import axios from 'axios'
import endpoints from './endpoints.json'
import { HttpError } from 'react-admin';

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getOwnShorts = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        sort: params.sort.field,
        order: params.sort.order,
        channelId:6 // hardcoded channel id for the moment to only show Toys and Colors
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.ownShortsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    const data = rawData.data.map(short=>{
        return {
            ...short,
            title: short.title,
            publish_date: short.publish_date?.substring(0,short.publish_date.lastIndexOf(':')).replace("T"," ").replace("00:00",""),
            views: Number.parseInt(short.views || 0).toLocaleString(),
            views_last_2h: (short.views_last_2h || 0).toLocaleString(),
            views_last_24h: (short.views_last_24h || 0).toLocaleString(),
            retention_percentage: (short.retention_percentage || 0) + '%',
            retention_percentage_first_ten_seconds: (short.retention_percentage_first_ten_seconds || 0) + '%',
            retention_first_thirty_seconds: (short.retention_first_thirty_seconds || 0) + '%'
        }
    })
    return {data:data,total:data.length}
        
}