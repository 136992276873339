import axios from 'axios'

import endpoints from './endpoints.json'
import { createThumbnails } from './thumbnailsDataSource';

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getOwnChannels = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: 20,
        sort: params.sort.field,
        order: params.sort.order
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.ownedChannelsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
    const mappedData = rawData.data.map(entry=> ({   
        id:entry.id,
        name: entry.name,
        project: entry.project,
        averageRetentionPercentage: entry.averageRetentionPercentage,
        averageRetentionDuration: secondsToHms(entry.averageRetentionDuration),
        averageViewsLast2h: entry.averageViewsLast2h,
        averageViewsLast24h: entry.averageViewsLast24h?.toLocaleString(),
        averageRetention: entry.averageRetention,
        videoIdeasUpdateFrequency: entry.videoIdeasUpdateFrequency,
        averageCtr: entry.averageCtr,
        trendsetterThreshold: entry.trendsetterThreshold
    })).filter(entry=> entry.name !== 'wprofile' && entry.name !== 'flocal' && entry.name !== 'TC Spanish'  && entry.name !== 'TC Toons'  && entry.name !== 'Bouncy Beats')
   // console.log(mappedData)
    return {data:mappedData,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: mappedData.length < perPage ? false : true}
    }
}

export const getOwnChannel = async(params)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.ownedChannelsEndpoint+'/'+params.id, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const mappedData = {   
        id:rawData.data.id,
        name: rawData.data.name,
        averageRetentionPercentage: rawData.data.averageRetentionPercentage,
        averageRetentionDuration: secondsToHms(rawData.data.averageRetentionDuration),
        averageViewsLast2h: rawData.data.averageViewsLast2h?.toLocaleString(),
        averageViewsLast24h: rawData.data.averageViewsLast24h?.toLocaleString(),
        averageRetention: JSON.parse(rawData.data.averageRetention),
        averageCtr: rawData.data.averageCtr,
        retentionSnapshotThreshold: rawData.data.retentionSnapshotThreshold?.toLocaleString(),
        averageRetentionPercentageSnapshot: rawData.data.averageRetentionPercentage_snapshot,
        averageRetentionDurationSnapshot: secondsToHms(rawData.data.averageRetentionDuration_snapshot),
        averageRetentionSnapshot: JSON.parse(rawData.data.averageRetention_snapshot),
        averageRetentionPercentageFirst3Minutes: Math.round(rawData.data.averageRetentionPercentageFirst3Min *10000)/100,
        averageRetentionPercentageFirst3Min_snapshot: Math.round(rawData.data.averageRetentionPercentageFirst3Min_snapshot *10000)/100,
        averageWeightedRetentionFirstMinute: Math.round(rawData.data.averageWeightedRetentionFirstMinute*100),
        averageWeightedRetentionFirstMinuteSnapshot: Math.round(rawData.data.averageWeightedRetentionFirstMinute_snapshot*100),
        averageRetentionFirstThirtySeconds: Math.round(rawData.data.averageRetentionFirstThirtySeconds*10000)/100,
        averageRetentionFirstThirtySecondsSnapshot: Math.round(rawData.data.averageRetentionFirstThirtySeconds_snapshot*10000)/100,
        averageCtr: rawData.data.averageCtr,
        videoIdeasUpdateFrequency: rawData.data.videoIdeasUpdateFrequency,
        thumbnailUpdateThreshold: rawData.data.thumbnailUpdateThreshold,
        trendsetterThreshold: rawData.data.trendsetterThreshold
    }
        return {data:mappedData}
}
export const getOwnChannelAverage = async(params)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.ownedChannelsEndpoint+'/'+params.channelId, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    //console.log(rawData.data)
    const mappedData = {   
        retentionSnapshotThreshold: rawData.data.retentionSnapshotThreshold?.toLocaleString(),
        averageRetentionPercentage: rawData.data.averageRetentionPercentage,
        averageRetentionPercentageSnapshot: rawData.data.averageRetentionPercentage_snapshot,
        averageRetentionDuration: secondsToHms(rawData.data.averageRetentionDuration),
        averageRetentionDurationSnapshot: secondsToHms(rawData.data.averageRetentionDuration_snapshot),
        averageRetention: JSON.parse(rawData.data.averageRetention),
        averageRetentionSnapshot: JSON.parse(rawData.data.averageRetention_snapshot),
        averageRetentionPercentageFirst3Minutes: Math.round(rawData.data.averageRetentionPercentageFirst3Min *10000)/100,
        averageRetentionPercentageFirst3Min_snapshot: Math.round(rawData.data.averageRetentionPercentageFirst3Min_snapshot *10000)/100,
        averageWeightedRetentionFirstMinute: Math.round(rawData.data.averageWeightedRetentionFirstMinute*100),
        averageWeightedRetentionFirstMinuteSnapshot: Math.round(rawData.data.averageWeightedRetentionFirstMinute_snapshot*100),
        averageRetentionFirstThirtySeconds: Math.round(rawData.data.averageRetentionFirstThirtySeconds*10000)/100,
        averageRetentionFirstThirtySecondsSnapshot: Math.round(rawData.data.averageRetentionFirstThirtySeconds_snapshot*10000)/100,
        averageCtr: rawData.data.averageCtr
    }
    //console.log(JSON.stringify(mappedData))
        return {data:mappedData}
}
export const updateRetentionThreshold = async(params)=>{
    const rawData = await axios.put(endpoints.ytAnalyticsUrl+endpoints.retentionSnapshotEndpoint, params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {message:{rawData}}
}
export const updateThumbnailThreshold = async(params)=>{
    const rawData = await axios.put(endpoints.backendUrl + endpoints.thumbnailsUpdateThresholdEndpoint, params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {message:{rawData}}
}
export const updateTrendsetterThreshold = async(params)=>{
    console.log(params)
    const rawData = await axios.put(endpoints.backendUrl + endpoints.trendsetterThresholdEndpoint, params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    console.log(rawData)
    return {message:{rawData}}
}
export const updateVideoIdeasUpdateFrequency = async(params)=>{
    const rawData = await axios.put(endpoints.backendUrl+endpoints.videoIdeasUpdateFrequencyEndpoint,{id:params.ownedChannelId, videoIdeasUpdateFrequency: params.videoIdeasUpdateFrequency}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {message:{rawData}}
}
export const getRelatedVideos = async(params)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.relatedVideosEndpoint+'/'+params.ownedChannelsId, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const mappedData = rawData.data.map( (entry,index)=> {
        return{
            id:index,
            videoId: entry.videoId,
            videoUrl:`https://www.youtube.com/watch?v=${entry.videoId}`,
            count: entry.count,
            views: entry.views?.toLocaleString(),
            isExternal: entry.isExternal > 0 ? true : false,
        }
    })
    return {data:mappedData, total:mappedData.length}
}



export const createOwnVideo = async(params)=>{
    const mappedParams = {
        ownedChannelsId: params.data.ownedChannelsId,
        videoId: getVideoId(params.data.videoUrl),
        publishDate: params.data.publishDate,
        title: params.data.title,
        thumbnail: ''
    }
    const thumbnails = await createThumbnails(mappedParams.videoId, params.data.images)
    //console.log(thumbnails)
    mappedParams.thumbnail = thumbnails.data.urls[0]
    const rawData = await axios.post(endpoints.backendUrl+endpoints.videoListEndpoint ,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`},params:mappedParams})
    //console.log(JSON.stringify(rawData.data))
    const mappedData = {
        id: `ownedChannelsId:${mappedParams.ownedChannelsId} itemId:${rawData.data.insertId}`,
        ownedChannelsId: mappedParams.ownedChannelsId,
        videoId: mappedParams.videoId,
        title: mappedParams.title,
        ctr: 0,
        thumbnail:mappedParams.thumbnail ? mappedParams.thumbnail : "https://flocal.s3.us-west-1.amazonaws.com/general/noThumbnailPlaceholder.png",
        publishDate: mappedParams.publishDate
    }
    //console.log(mappedData)
    return {data:mappedData}

}

export const deleteOwnVideos = async(ids) => {
    const ownedVideosIds = ids.map(id=>id.slice(id.indexOf('_itemId-')+8))
    await axios.delete(`${endpoints.backendUrl+endpoints.videoListEndpoint}`,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: {ids: ownedVideosIds.join()}})
    return {data:ids}
}

export const getOwnVideos = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        ownedChannelsId: params.filter.Channel,
        searchPhrase: params.filter.searchPhrase,
        hasMultipleThumbnails:params.filter.hasMultipleThumbnails,
        creatorSearch: params.filter.creatorSearch,
        publishDate: params.filter.publishDate ? getMySQLDatetimeDaysAgo(params.filter.publishDate) : '',
        sort: params.sort.field === "id" ? "publishDate" : params.sort.field,
        order: params.sort.field === "id" ? "DESC" : params.sort.order
    }
    if(!queryParams.ownedChannelsId){
        let channelId = JSON.parse(localStorage.getItem("defaultChannel"))?.id
        queryParams.ownedChannelsId = channelId ? channelId : 1
    }

     //console.log(queryParams)
    const rawData = await axios.get(endpoints.backendUrl+endpoints.videoListEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
    //console.log(rawData.data)
    let mappedData = rawData.data.map( entry=> {
        return{
            id: `ownedChannelsId-${entry.ownedChannels_id}_itemId-${entry.id}`,
            ownedChannelsId: entry.ownedChannels_id,
            videoId: entry.videoId,
            title: entry.title,
            ctr: entry.ctr,
            thumbnail: removeQuotes(entry.thumbnail),
            publishDate: entry.publishDate.slice(0,entry.publishDate.lastIndexOf('T')), 
            views: entry.views?.toLocaleString(),
            viewsLast24hSum: entry.viewsLast24hSum?.toLocaleString(),
            viewsLast2h: entry.viewsLast2h?.toLocaleString(),
           // retention: JSON.parse(entry.retention),
            retentionPercentage: Math.round(entry.retentionPercentage*100)/100,
            retentionPercentageFirst3Min: Math.round(entry.retentionPercentageFirst3Min*10000)/100,
            retentionFirstThirtySeconds: Math.round(entry.retentionFirstThirtySeconds*10000)/100,
            weightedRetentionPercentageFirstMin: Math.round(entry.weightedRetentionPercentageFirstMin*100),
            duration: convertYoutubeTimeString(entry.duration),
            retentionDuration: secondsToHms(entry.retentionDuration),
            likes:  entry.likes?.toLocaleString(),
            ctr: entry.ctr,
            channelId: entry.channel_id,
            retentionPercentage_snapshot: Math.round(entry.retentionPercentage_snapshot*100)/100,
            retentionPercentageFirst3Min_snapshot: Math.round(entry.retentionPercentageFirst3Min_snapshot*10000)/100,
            retentionFirstThirtySeconds_snapshot: Math.round(entry.retentionFirstThirtySeconds_snapshot*10000)/100,
            weightedRetentionPercentageFirstMin_snapshot: Math.round(entry.weightedRetentionPercentageFirstMin_snapshot*100),
            ctr_snapshot: entry.ctr_snapshot
        }
    })
   // console.log(mappedData)
    if(!params.filter.showUnpublished){
        mappedData = mappedData.filter(item=> videoIsPublished(item.publishDate) )
    }
    return {data:mappedData,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: mappedData.length < perPage ? false : true}
    }
}
export const getOwnVideo = async(params)=>{
    console.log(params.id)
    const videoId = params.id.slice(params.id.indexOf('_itemId-')+8)
    const ownedChannelsId = params.id.slice(params.id.indexOf('ownedChannelsId-')+16,params.id.indexOf('_itemId-'))
    const rawData = await axios.get(endpoints.backendUrl+endpoints.videoListEndpoint+'/'+videoId, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: {ownedChannelsId: ownedChannelsId}})
    const mappedData = rawData.data.map( entry=> {
        return{
            id: params.id,
            ownedChannelsId: entry.ownedChannels_id,
            videoId: entry.videoId,
            title: entry.title,
            ctr: entry.ctr,
            thumbnail: entry.thumbnail?.replaceAll("'",""),
            publishDate: entry.publishDate?.slice(0,entry.publishDate.lastIndexOf('T')), 
            views: entry.views?.toLocaleString(),
            viewsLast24hSum: entry.viewsLast24hSum?.toLocaleString(),
            viewsLast2h: entry.viewsLast2h?.toLocaleString(),
            retention: JSON.parse(entry.retention),
            retentionPercentage: Math.round(entry.retentionPercentage * 100)/100,
            retentionPercentageFirst3Min: Math.round(entry.retentionPercentageFirst3Min * 10000)/100,
            retentionFirstThirtySeconds: Math.round(entry.retentionFirstThirtySeconds*10000)/100,
            weightedRetentionPercentageFirstMin: Math.round(entry.weightedRetentionPercentageFirstMin*100),
            duration: convertYoutubeTimeString(entry.duration),
            retentionDuration: secondsToHms(entry.retentionDuration),
            likes:  entry.likes?.toLocaleString(),
            ctr: entry.ctr,
            channelId: entry.channel_id,
            retentionPercentage_snapshot: Math.round(entry.retentionPercentage_snapshot*100)/100,
            retentionPercentageFirst3Min_snapshot: Math.round(entry.retentionPercentageFirst3Min_snapshot*10000)/100,
            retentionFirstThirtySeconds_snapshot : Math.round(entry.retentionFirstThirtySeconds_snapshot*10000)/100,
            weightedRetentionPercentageFirstMin_snapshot: Math.round(entry.weightedRetentionPercentageFirstMin_snapshot*100),
            ctr_snapshot: entry.ctr_snapshot
        }

    })
    return {data:mappedData[0]}
}

export const createVideoCreator = async(params)=>{
    
    const rawData = await axios.post(endpoints.backendUrl+endpoints.videoCreatorEndpoint,params,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    //console.log(rawData)
    return {data:{id:0}}
}

export const getOwnVideoCreators = async(params)=>{
    const videoId = params.videoId.slice(params.videoId.indexOf('@')+1)
    const rawData = await axios.get(`${endpoints.backendUrl+endpoints.videoCreatorEndpoint}?videoId=${videoId}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:rawData.data}
}

export const updateVideoCreator = async(params)=>{
    const rawData = await axios.put(endpoints.backendUrl+endpoints.videoCreatorEndpoint, params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:{id:rawData?.data?.insertId}}
}

export const deleteVideoCreators = async(ids) => {
    await axios.delete(`${endpoints.backendUrl+endpoints.videoCreatorEndpoint}`,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: {ids: ids.join()}})
    return {data:ids}
}

export const getLatestVideoPerformances = async(params)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.latesVideoPerformancesEndPoint+'/'+params.ownedChannelsId, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const mappedData = rawData.data.map( entry=> {
        return{
            ...entry,
            date: entry.date.slice(0,entry.date.lastIndexOf('T'))
        }
    })
    return {data:mappedData, total:mappedData.length}
}

// change seconds to hh:mm:ss
const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    return ((h > 0 ? "0" + h + ":" : "") + (m < 10 ? "0" + m + ":" :  m + ":") + (s < 10 ? "0" : "") + s);
}

const convertYoutubeTimeString = (timeString) =>{
    let convertedString = timeString.replace("PT","").replace("H",":").replace("M",":").replace("S","")
    let timeArr = convertedString.split(":")
    let timeStringConverted = ""
    timeArr.forEach(item => {
        if(item.length === 1)
            item = "0"+item
        if(item.length !== 0)
            timeStringConverted += item + ":"
    }
    )
    if(timeString.indexOf("M") === -1 && timeString.indexOf("H") === -1 && timeString.indexOf("S") === -1)
        timeStringConverted = "00:00:"
    else if(timeString.indexOf("M") === -1 && timeString.indexOf("H") === -1)
        timeStringConverted = "00"+timeStringConverted
    else if(timeString.indexOf("H") === -1 && timeString.indexOf("S") == -1)
        timeStringConverted = timeStringConverted + "00:"
    return timeStringConverted.slice(0,-1)
}
//getvideoId from youtube url
const getVideoId = (url) => {
    const id = url.split('v=')[1];
    if(id){
        const ampersandPosition = id.indexOf('&');
        if(ampersandPosition !== -1) {
        return id.substring(0, ampersandPosition);
        }
        return id;
    }
    return url.substring(url.lastIndexOf('/') + 1);
}

const removeQuotes = (str) =>{
    if(!str)
        return ""
    if (str.startsWith("'") && str.endsWith("'")) {
      return str.slice(1, -1);
    }
    return str;
  }
  const videoIsPublished = (publishDate) =>{
    const videoDate = new Date(publishDate);
    const currentDate = new Date();
    return videoDate.getTime() <= currentDate.getTime()
  }

  const getMySQLDatetimeDaysAgo = (daysAgo) => {
    const date = new Date(); // Current date and time
    date.setDate(date.getDate() - daysAgo); // Subtract daysAgo from current date

    // Build MySQL datetime string
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hour = ("0" + date.getHours()).slice(-2);
    const minute = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);
    
    // YYYY-MM-DD HH:MM:SS
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}