import axios from 'axios'
import endpoints from './endpoints.json'

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getVideoIdeas = async(ownedChannelsId)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.videoIdeasEndpoint+`?ownedChannelsId=${ownedChannelsId}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const mappedData = rawData.data.map((video)=>{
        let sourceVideoIds = JSON.parse(video.sourceVideoIds.replaceAll('//', ''))
        return {...video, sourceVideoIds: sourceVideoIds} })
    //console.log(rawData)
    return mappedData
}

export const reserveVideoIdea = async(id, ownedChannelsId, reserverEmail)=>{
    const rawData = await axios.put(endpoints.backendUrl+endpoints.videoIdeasEndpoint+`?id=${id}&ownedChannelsId=${ownedChannelsId}&reserverEmail=${reserverEmail}`, {}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    //console.log(rawData)
    return true
}

export const saveVideoIdea = async(params)=>{
    const rawData = await axios.post(endpoints.backendUrl+endpoints.videoIdeasEndpoint+'/saved', params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const result = {...params, id: rawData.data.insertId}
    return {data: result}
}

export const deleteSavedVideoIdea = async(params)=>{
    await axios.delete(endpoints.backendUrl+endpoints.videoIdeasEndpoint+'/saved'+`?id=${params.id}&idea=${params.idea}&owner=${params.owner}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    await axios.put(endpoints.backendUrl+endpoints.videoIdeasEndpoint+`?idea=${params.idea}&reserverEmail=${params.owner}`,{}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return true
}

export const getSavedVideoIdeas = async(params)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.videoIdeasEndpoint+'/saved'+`?ownedChannelsId=${params.meta.ownedChannelsId}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const mappedData = rawData.data.map((row)=>{
        let sourceVideoIds = JSON.parse(row.sourceVideoIds.replaceAll('//', ''))
        return {...row, timestamp: row.timestamp.replace('T',' ').replace(/\..{4}/,''), sourceVideoIds: sourceVideoIds} 
    })
    const mySavedVideoIdeas = mappedData.filter((row)=>row.owner === params.meta.owner)
   // const otherSavedVideoIdeas = mappedData.filter((row)=>row.owner !== params.owner)
   // console.log(mySavedVideoIdeas)
    return {data:[{id:0, mySavedVideoIdeas: mySavedVideoIdeas, savedVideoIdeas: mappedData}], total: mappedData.length}
}

export const generateThumbnailIdeas = async(params)=>{
    const rawData = await axios.put(endpoints.backendUrl+endpoints.videoIdeasEndpoint+'/saved/thumbnailIdea',params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    console.log(rawData)
    return rawData.data
}