import React from 'react';
import { 
    Create,
    SimpleForm,
    ImageField,
    TextInput,
    DateTimeInput,
    ImageInput,
    SelectInput
    } from 'react-admin';
export const OwnVideoCreate = () => (
    <Create redirect="list">
      <SimpleForm>
        <SelectInput source ='ownedChannelsId' choices={[
            { id: 1, name: 'Toys and Colors' },
            { id: 2, name: 'Kids Play' },
            { id: 3, name: 'Toys Play' },
            { id: 5, name: 'Sweedee' },
            { id: 6, name: 'Crafty Hype' },
            { id: 7, name: 'Crafty Hacks' },
            { id: 8, name: 'Crafty Hacks Plus' },
            { id: 14, name: 'Crafty Deals' }
        ] } defaultValue={1} />
        <DateTimeInput source="publishDate" label="Publishing at" />
        <TextInput source="videoUrl" label="Video URL"  fullWidth/>
        <TextInput source="title" label="Title"  multiline fullWidth/>
        <ImageInput source="images" label="Thumbnails" accept="image/*" maxSize={2000000} multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )