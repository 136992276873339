import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { Auth } from 'aws-amplify';
Auth.configure({
    region: 'us-west-1',
    userPoolId: "us-west-1_676g5JNBl",
    userPoolWebClientId: '2oq5ufu9f7iubpcihf5vancf7l'
})

const LoginPage = ({ theme }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showReset, setShowReset] = useState(false);
    const login = useLogin();
    const notify = useNotify();

    const paperStyle={padding :20,height:'70vh',width:280, margin:"20px auto"}
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const btnstyle={margin:'8px 0'}

    const handleSubmit = e => {
        e.preventDefault();
        login({ username, password }).catch(() =>
            notify('Invalid email or password')
        );
    };

    const handleButtonClick = (e) => {
        setShowReset(!showReset);
    };

    return (        
        <Grid>
        <Paper elevation={10} style={paperStyle}>
            <Grid align='center'>
                 <Avatar style={avatarStyle}></Avatar>
                <h2>Sign In</h2>
            </Grid>
            <form onSubmit={handleSubmit}>
            <TextField label='username' placeholder='Enter email' variant="outlined" fullWidth required value={username} onChange={e => setUsername(e.target.value)}/>
            <TextField label='Password' placeholder='Enter password' type='password' variant="outlined" fullWidth required value={password} onChange={e => setPassword(e.target.value)}/>
            <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Sign in</Button>
            </form>
            <Typography >
                <Button color='primary' variant="text" style={btnstyle} fullWidth onClick={e=>handleButtonClick(e)}>Forgot Password?</Button>
            </Typography>
            <PasswordForgot showReset={showReset}/>
        </Paper>
    </Grid>
    );
};
const PasswordForgot = ({ showReset }) => {
    const [email, setEmail] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const notify = useNotify();
    const onSubmit= (e) => {
        Auth.forgotPassword(email).then(data => {
        setShowSuccess(true);
        notify('Email sent');
        setShowNewPassword(true);
        }).catch(err => {
            notify('Error sending email');
        })
    }
    return (
        <>
            {(showReset && !showNewPassword) && (
                <>
                    <Typography variant="body2">
                        Enter your email address below and we'll send you a code to
                        reset your password.
                    </Typography>
                    <TextField
                        label='Email'
                        placeholder='Enter email'
                        variant="outlined"
                        fullWidth
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Button color='primary' variant="contained" fullWidth onClick={e=>onSubmit(e)}>Send Reset Link</Button>
                </>
                
            )}
            {showNewPassword && (
                <NewPassword email={email}/>
            )}
            </>
    );
};
const NewPassword = ({email }) => {
    const [password, setPassword] = useState('')
    const [passwordRetype, setPasswordRetype] = useState('');
    const [code, setCode] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const notify = useNotify();
    const onSubmit= (e) => {
        if(password !== passwordRetype) {
            notify('Passwords do not match');
            return;
        }
        Auth.forgotPasswordSubmit(email, code, password).then(data => {
            console.log(data);
            setShowSuccess(true);
            notify('Password reset!');
        }).catch(err => {
            notify('Error resetting password');
        })
    }
    return (
                <>
                    <Typography variant="body2">
                        Enter the code we sent you via email
                    </Typography>
                    <TextField
                        label='Reset Code'
                        type="text"
                        placeholder='Enter Reset Code'
                        variant="outlined"
                        fullWidth
                        required
                        value={code}
                        onChange={e => setCode(e.target.value)}
                    />
                    <TextField
                        label='New Password'
                        type="password"
                        variant="outlined"
                        fullWidth
                        required
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                     <TextField
                        label='Confirm New Password'
                        type="password"
                        variant="outlined"
                        fullWidth
                        required
                        value={passwordRetype}
                        onChange={e => setPasswordRetype(e.target.value)}
                    />
                    <Button color='primary' variant="contained" fullWidth onClick={e=>onSubmit(e)}>Reset Password</Button>
                </>
    );
};
export default LoginPage;