import React, {useState, useEffect} from 'react';
import {
    Title,
    useDataProvider, 
    useCreateController,
    SimpleForm,
    ImageInput, 
    ImageField,
    TextInput,
    Loading,
    useNotify,
    useGetIdentity,
 } from 'react-admin';
import {Grid} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const ImageHandler = (props) => {
    const { identity, identityLoading } = useGetIdentity()
    const [selected, setSelected] = useState("create");
    const [generatedImage, setGeneratedImage] = useState(null);
    const [reloadSavedImages, setReloadSavedImages] = useState(0);

    useEffect(()=>{
        if(selected === "create" || selected === "combine"){
        setGeneratedImage(null);
        }
    },[selected])

    const updateSelectedImage = (image) => {
        setGeneratedImage(image);
        if(image)
            setSelected("variation");
    }
    if(identityLoading || !identity) return <Loading />
    return(
        <>
            <Title title="AI Image Generation" />
            <Grid container>
                <Grid item xs ={2}>
                    <Paper sx={{height:"100%", padding:"20px 10px 0 10px"}}>
                        <SavedImages owner ={identity.fullName} updateSelectedImage={updateSelectedImage} reloadSavedImages={reloadSavedImages}/>
                    </Paper>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{marginTop:"50px"}}>
                        <Grid item xs ={3}>
                            <Card style={selected === "create" ? activeCardCss : cardCss} onClick={(e)=>setSelected("create")} >
                                <Box display="flex" alignItems="center"><PhotoCameraBackIcon /><h3 style={{ marginLeft: 8 }}>Create Images</h3></Box>
                                <Box style={{textAlign:"center", marginLeft:"5px", marginRight:"5px"}}>Create images by giving a prompt to the AI and it will generate images based on your description.</Box>                    
                            </Card>
                        </Grid>
                        <Grid item xs ={3}>
                            <Card style={selected === "combine" ? activeCardCss : cardCss} onClick={(e)=>setSelected("combine")} >
                                <Box display="flex" alignItems="center"><PhotoCameraBackIcon /><h3 style={{ marginLeft: 8 }}>Combine Images</h3></Box>
                                <Box style={{textAlign:"center", marginLeft:"5px", marginRight:"5px"}}>Upload several images to create a new image that is a combination of the original ones.</Box>                    
                            </Card>
                        </Grid>
                        <Grid item xs ={3}>
                            <Card style={selected === "variation" ? activeCardCss : cardCss} onClick={(e)=>setSelected("variation")} >
                                <Box display="flex" alignItems="center"><PhotoCameraBackIcon /><h3 style={{ marginLeft: 8 }}>Create Variations</h3></Box>
                                <Box style={{textAlign:"center", marginLeft:"5px", marginRight:"5px"}}>Upload an image to the AI to generate one or more variations of the original image.</Box>                    
                            </Card>
                        </Grid>
                    </Grid>
                    {selected === "create" && <ImageCreator generatedImage ={generatedImage} setGeneratedImage = {updateSelectedImage} owner ={identity.fullName} setReloadSavedImages ={setReloadSavedImages} />}
                    {selected === "combine" && <ImageCombiner setGeneratedImage = {updateSelectedImage} owner ={identity.fullName} setReloadSavedImages ={setReloadSavedImages}/>}
                    {selected === "variation" && <ImageVariator generatedImage ={generatedImage} setGeneratedImage = {updateSelectedImage} owner ={identity.fullName} setReloadSavedImages ={setReloadSavedImages}/>}
                </Grid>
            </Grid>
        </>
    )
}

const ImageCreator = ({generatedImage, setGeneratedImage, owner, setReloadSavedImages}) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const onSuccess = (data) => {
        setTimeout(()=>{
            setGeneratedImage(data.generatedImage);
            setReloadSavedImages(Math.random());
        },1000);
        setLoading(false);
    }
    const handleSubmit = (values) => {
        setLoading(true);
        save(values);
    }
    const transform = (data) => ({...data, owner:owner})
    const { save } = useCreateController({resource: 'imageCreator', transform:transform, mutationOptions: {
        onSuccess: onSuccess, 
        onError:(error)=>{
            setLoading(false)
            notify(error, { type: 'error', autoHideDuration: 30000, multiLine: true })
        }}});
    if(loading) return <Loading />
    return(
        <>
        {generatedImage && <Grid container justifyContent="center" alignItems="center">
            <Grid item display="flex" justifyContent="center" xs={12} sx={{margin:"10px"}}><img src={`${generatedImage}`} width="600px" /></Grid>
        </Grid>}
        <SimpleForm toolbar={false} onSubmit={handleSubmit}>
        <TextInput source="prompt" label="Image Description" fullWidth />
        <Button variant ="contained" type='submit' className='plausible-event-name=AI+Image+Creator+Button+clicked'>Create Image</Button>
        </SimpleForm>
        </>
    )
}

const ImageCombiner = ({setGeneratedImage, owner, setReloadSavedImages}) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState("");
    const onSuccess = (data) => {
        //console.log(answer);
        setTimeout(()=>{
            setGeneratedImage(data.generatedImage);
            setReloadSavedImages(Math.random());
        },1000);
        setLoading(false);
    }
    const handleSubmit = (values) => {
        setLoading(true);
        try{
            save(values);
        }
        catch(err){
            notify(err, { type: 'error', autoHideDuration: 30000, multiLine: true })
        }
    }
    


    const transform = (data) => ({...data, owner:owner})
    const { save } = useCreateController({resource: 'imageCombiner', transform:transform, mutationOptions: {
        onSuccess: onSuccess, 
        onError:(error)=>{
            setLoading(false)
            notify(error, { type: 'error', autoHideDuration: 30000, multiLine: true })
        }}});
    if(loading) return <Loading />
    return(
    <>
        
        <SimpleForm toolbar={true} onSubmit={handleSubmit}>
        <Box sx={{ textAlign: 'center', width: '100%' }}><h5>Use URLs of multiple Images or upload them from your computer</h5></Box>
        <TextInput source="imageUrls" label="Image URLs (one per line)" fullWidth multiline onChange={(e)=>setImageUrls(e.target.value)} />
        {imageUrls.length === 0 &&
            <ImageInput source="images" label=" "  accept="image/*" multiple >
                <ImageField source="src" title="title" />
            </ImageInput>
        }
            <Button variant ="contained" type='submit' className='plausible-event-name=AI+Image+Combiner+Button+clicked'>Combine Images</Button>
        </SimpleForm>
    </>
    )
}

const ImageVariator = ({generatedImage, setGeneratedImage, owner, setReloadSavedImages}) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const [sourceImages, setSourceImages] = useState([]);
    const [prompt, setPrompt] = useState(null)

    const [imageUrl, setImageUrl] = useState("");

    const onSuccess = (data) => {
        //console.log(answer);
        setSourceImages(data.sourceImages);
        setTimeout(()=>{
            setGeneratedImage(data.generatedImage);
            setReloadSavedImages(Math.random());
        },1000);       
        setLoading(false);
    }
    const handleSubmit = (values) => {
        setLoading(true);
        save(values);
    }
    
    const regenerateImage = () => {
        setLoading(true);
        dataProvider.create('imageVariator', {data:{imageUrls:[generatedImage], prompt:prompt, owner:owner}}).then((result)=>{
            setTimeout(()=>{
                setGeneratedImage(result.data.generatedImage);
                setReloadSavedImages(Math.random());
            },1000);
        }).catch(error => notify(error, { type: 'error', autoHideDuration: 30000, multiLine: true })).finally(()=>setLoading(false));
    }

    const transform = (data) => ({...data, owner:owner})

    const { save } = useCreateController({resource: 'imageVariator', transform:transform, mutationOptions: {
        onSuccess: onSuccess, 
        onError:(error)=>{
            setLoading(false)
            notify(error, { type: 'error', autoHideDuration: 30000, multiLine: true })
        }}});
    if(loading) return <Loading />
    return(
    <>
        {
        generatedImage ? 
        <Grid container justifyContent="center" alignItems="center">
            <Grid item display="flex" justifyContent="center" xs={12} sx={{margin:"10px"}}><img src={`${generatedImage}`} width="600px" /></Grid>
            <Grid item xs={12}>
                <SimpleForm toolbar={false}>
                    <TextInput source="prompt" label="Describe desired changes (Optional)" fullWidth onChange={(e) => setPrompt(e.target.value)} />
                </SimpleForm>
            </Grid>
            <Grid item xs ={2} sx={{marginLeft:"15px"}}><Button variant ="contained" onClick={regenerateImage}>Regenerate Image</Button></Grid>
            <Grid item xs ={2} sx={{marginLeft:"15px"}}><Button variant ="contained" color ="warning" onClick={(e)=>{setGeneratedImage(null);setImageUrl('');}}>Select New Image</Button></Grid>
        </Grid>
        :
        <SimpleForm toolbar={true} onSubmit={handleSubmit}>
            <Box sx={{ textAlign: 'center', width: '100%' }}><h5>Use an URL of an Image or upload it from your computer</h5></Box>

            <TextInput source="imageUrl" label="Image URL" fullWidth onChange={(e)=>setImageUrl(e.target.value)} />
            {imageUrl.length === 0 &&
            <ImageInput source="images" label=" "  accept="image/*" >
                <ImageField source="src" title="title" />
            </ImageInput>
            }
            <TextInput source="prompt" label="Describe desired changes (Optional)" fullWidth />
            <Button variant ="contained" type='submit' className='plausible-event-name=AI+Image+Variation+Button+clicked'>Create Variation</Button>
        </SimpleForm>
        }
    </>
    )
}

const SavedImages = ({owner, updateSelectedImage, reloadSavedImages}) => {
    const dataProvider = useDataProvider();
    const [images, setImages] = useState([]);
    useEffect(()=>{   
        dataProvider.getList('aiGeneratedImages',{filter: {owner:owner}}).then((result)=>setImages(result.data)).catch((error)=>console.log(error));
    },[reloadSavedImages])

    const handleRemoveImage = (index) => {
        dataProvider.delete('aiGeneratedImages', {url:images[index].url}).then((result)=>{
            const newImages = images.filter((image, i)=>i!==index);
            setImages(newImages);
        }).catch((error)=>console.log(error));
    }
    return(
        <>
            <h3>Generated Images (Last 30 Days)</h3>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {images.map((image, index)=>
                    <Grid item key={index} display="flex" justifyContent="center" xs={12} sx={{margin:"10px", position: 'relative'}}>
                        <img style={{cursor:"pointer"}} src={`${image.url}`} width="100px" onClick={(e)=>updateSelectedImage(image.url)} />
                        <HighlightOffIcon
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                color: 'red',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleRemoveImage(index)}
                        />                        
                        </Grid>
                )}
            </Grid>
        </>
    )
}

const cardCss = {display: 'flex',justifyContent: 'center',alignItems: 'center',height: '150px',flexDirection: 'column', paddingBottom:"10px", cursor: 'pointer'}
const activeCardCss = {display: 'flex',justifyContent: 'center',alignItems: 'center',height: '150px',flexDirection: 'column', paddingBottom:"10px", cursor: 'pointer', color:"white", backgroundColor:"#2296F3"}  