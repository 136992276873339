import AWS from 'aws-sdk'
AWS.config.region = 'us-west-1';

export const getS3Bucket = async() => {
    return new Promise((resolve, reject) => {
        const idToken = localStorage.getItem('idToken')
        if(!idToken) reject('No idToken found');
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-1:0453f00d-cddf-4427-bdcf-802692934eaa',
            Logins: {
                'cognito-idp.us-west-1.amazonaws.com/us-west-1_676g5JNBl': idToken,
            }
        });
        AWS.config.credentials.get(function (err) {
            if (err) {
                console.log(err);
                reject(err);
            } else {
                resolve(new AWS.S3());
            }
        });
    });
}
