import { AppLocationContext, MultiLevelMenu } from '@react-admin/ra-navigation';
import { Layout,usePermissions } from 'react-admin';

import CollectionsIcon from '@mui/icons-material/Collections';
import VideoLibrary from '@mui/icons-material/VideoLibrary';
import ViewList from '@mui/icons-material/ViewList';
import Abc from '@mui/icons-material/Abc';
import DashboardIcon from '@mui/icons-material/Dashboard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ImageIcon from '@mui/icons-material/Image';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CategoryIcon from '@mui/icons-material/Category';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import ListIcon from '@mui/icons-material/List';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';


const MyMenu = () => {
    const { permissions } = usePermissions();
    return(
    <MultiLevelMenu initialOpen>
        <MultiLevelMenu.Item name="dashboard" to="/" label="Dashboard" icon={<DashboardIcon />} />
        {(permissions === 'superuser') &&
            <MultiLevelMenu.Item name ='projects' to="/projects" label="Projects" icon={<CategoryIcon />} ></MultiLevelMenu.Item>
        }
        {(permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser' ) &&
        <>
        <MultiLevelMenu.Item name ='videoIdeas' to="#" label="Video Ideas" icon={<TipsAndUpdatesIcon />} >
        <MultiLevelMenu.Item name ='videoIdeas' to="/videoIdeas" label="Video Ideas List" icon={<ListIcon />}/>
        <MultiLevelMenu.Item name ='videoIdeas' to="/savedVideoIdeas" label="Reserved Ideas" icon={<BookmarkAddedIcon />}/>
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name ='ownChannels' to="#" label="Own Channels" icon={<ViewList />} >
            {(permissions === 'superuser') &&<MultiLevelMenu.Item name ='Add Own Channel' to='/youtubeOAuth' label="Add Own Channel" icon={<Abc />}></MultiLevelMenu.Item>}
            <MultiLevelMenu.Item name ='ownChannels' to="/ownChannels?filter={}" label="Channels" icon={<CollectionsIcon />}/>
            <MultiLevelMenu.Item name ='ownVideos' to='/ownVideos?displayedFilters=%7B%7D&order=DESC&page=1&perPage=25&sort=publishDate' label="Videos" icon={<VideoLibrary />}/>
            <MultiLevelMenu.Item name ='VideoPerformances' to='/videoPerformances' label="Video Performances" icon={<QueryStatsIcon />}></MultiLevelMenu.Item>
            {(permissions === 'superusers' || permissions === 'managers') &&
                <MultiLevelMenu.Item name ='thumbnails' to='/thumbnails/create' label="Add Thumbnails" icon={<ImageIcon/>}/>
            }
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name ='Shorts' to="#" label="Shorts" icon={<ViewList />} >
            <MultiLevelMenu.Item name ='ownShorts' to='/ownShorts' label="Own Shorts" icon={<VideoLibrary />}/>
        </MultiLevelMenu.Item>
        </>
        }
        <MultiLevelMenu.Item name ='externalChannels' to="#" label="External Channels" icon={<ViewList />} >
            <MultiLevelMenu.Item name ='externalChannels' to='/externalChannels' label="Channels" icon={<CollectionsIcon />}/>
            <MultiLevelMenu.Item name ='externalVideos' to='/externalVideos' label="Videos" icon={<VideoLibrary />}/>
        </MultiLevelMenu.Item>
        {/* <MultiLevelMenu.Item name ='videoProposals' to="#" label="Proposals" icon={<ViewList />}>
            <MultiLevelMenu.Item name ='videoProposals' to='/videoProposals?displayedFilters=%7B%7D&filter=%7B"project"%3A"_kids"%7D&order=DESC&page=1&perPage=25&sort=id' label="Video Proposals" icon={<Abc />}></MultiLevelMenu.Item>
            <MultiLevelMenu.Item name ='propsProposals' to='/propsProposals?displayedFilters=%7B%7D&filter=%7B"project"%3A"_kids"%7D&order=DESC&page=1&perPage=25&sort=id' label="Props Proposals" icon={<Abc />}></MultiLevelMenu.Item>
        </MultiLevelMenu.Item> */}
        {/* <MultiLevelMenu.Item name ='trendingTags' to='/trendingTags?displayedFilters=%7B%7D&filter=%7B"project"%3A"_kids"%7D&order=DESC&page=1&perPage=25&sort=timestamp' label="Trending Tags" icon={<Abc />}></MultiLevelMenu.Item> */}
        <MultiLevelMenu.Item name ='Users' to="#" label="Users" icon={<ViewList />} >
            <MultiLevelMenu.Item name ='userData' to='/userData?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id' label="Users" icon={<PeopleIcon />}/>
            {(permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser' ) &&
            <>
                <MultiLevelMenu.Item name ='viewsAnalyticsLastXDays' to='/ViewAnalyticsLastXDays' label="Daily Views" icon={<VisibilityIcon />}/>
                <MultiLevelMenu.Item name ='viewsAnalytics' to='/viewsAnalytics' label="Detailed Daily Views" icon={<VisibilityIcon />}/>
                <MultiLevelMenu.Item name ='monthlyViewsAnalytics' to='/monthlyViewsAnalytics?displayedFilters=%7B%7D&filter=%7B"date"%3A"2023-02-01"%2C"team"%3A"all"%2C"showTotal"%3Atrue%7D&order=DESC&page=1&perPage=25&sort=date' label="Monthly Views" icon={<VisibilityIcon />}/>
            </>
            }
            {(permissions === 'superuser' || permissions === 'manager') &&
                <MultiLevelMenu.Item name ='userRoles' to='/roles' label="Roles" icon={<AssignmentIndIcon />}/>
            }
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name ='ChatGpt' to="#" label="ChatGpt" icon={<ViewList />}>
            <MultiLevelMenu.Item name ='Chat' to='/chat' label="Chat" icon={<Abc />}></MultiLevelMenu.Item>
            <MultiLevelMenu.Item name ='VideoScriptGenerator' to='/videoScriptGenerator' label="Generate Script" icon={<EditNoteIcon />}></MultiLevelMenu.Item>
            <MultiLevelMenu.Item name ='VideoScriptFixer' to='/videoScriptFixer' label="Fix Script" icon={<AutoFixHighIcon />}></MultiLevelMenu.Item>
            <MultiLevelMenu.Item name ='prompts' to='/prompts' label="Custom Prompts" icon={<Abc />}></MultiLevelMenu.Item>
            <MultiLevelMenu.Item name ='imageHandler' to='/imageHandler' label="Images" icon={<PhotoCameraBackIcon />}></MultiLevelMenu.Item>
        </MultiLevelMenu.Item>
    </MultiLevelMenu>
)
    }


export const MyMenuLayout = props => <AppLocationContext><Layout {...props} menu={MyMenu} ></Layout></AppLocationContext>;