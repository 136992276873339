import React, {useEffect, useState} from "react";
import { useSearchParams } from 'react-router-dom'
import endpoints from '../../admin/dataProviders/endpoints.json'
import axios from 'axios'
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

export const YoutubeOAuth = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    return (<>
        {searchParams.get('code') ? <AddChannel code = {searchParams.get('code')+"&scope="+searchParams.get('scope')}/> : <Authenticate/>}
        </>
    )
}

const AddChannel = (code) => {
    const [message, setMessage] = useState('Retrieving Access Token...')
    
    useEffect(() => {
       
        const data = localStorage?.getItem('authChannel')
        if(data){
            try{               
            (async() => {   
                console.log(code)
                const params = JSON.parse(data)
                const res = await axios.post(`${endpoints.ytAnalyticsUrl}/youtubeAnalytics/addoAuthForChannel`, 
                {videoId:params.videoId,
                project:params.project,
                code:code})
                console.log(res)
                setMessage("Server Response: ",JSON.stringify(res.data))
            })()
        }catch(err){
            console.log(err)
        }
    }
    },[])
    return (
        <div>
            {message}
            {message.includes('Server Response') ? <a href="https://www.ntmdata.com/youtubeOAuth"> Click to continue</a> : null}
        </div>
    )
}
const Authenticate = () => {
    const [videoId, setVideoId] = useState('')
    const [project, setProject] = useState(0)
    const [authUrl, setAuthUrl] = useState('')

    useEffect(() => {
        localStorage.removeItem('authChannel')
        axios.get(`${endpoints.ytAnalyticsUrl}/health`).then(res => console.log(res.data))
    },[])

    const getVideoId = (url) => {
        const videoId = url.split('v=')[1]
        setVideoId(videoId)
    }
    const createAuthentication = () => {
        if(project === 0) return alert('Please choose a project')
        else if(!videoId) return alert('Please enter a valid video URL')
        else{
            localStorage.setItem('authChannel', JSON.stringify({videoId: videoId, project: project}))            
            axios.get(`${endpoints.ytAnalyticsUrl}/youtubeAnalytics/getAuthUrl`,{params: {}})
                .then(res => {
                    setAuthUrl(res.data.url)
                    console.log(res.data.url)
                })
                .catch(err => console.log(err))
        }
    }
    
    return (
        <Paper elevation={3} sx={{width: '50%', margin: 'auto', padding: '20px'}}>   
        <h3>Add new Own Channel and get authentication for Youtube Analytics</h3>
        <ol>
            <li>Choose the project you want to add the channel to</li>
            <li>Enter the URL of a video of the new channel you want to add</li>
            <li>Click <i>Create Authentication</i></li>
            <li>Click the link to Authenticate with Google</li>
        </ol>
        <FormControl fullWidth>  
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={project}
                label="Project"
                variant="filled"
                onChange={e=>setProject(e.target.value)}
                >
                <MenuItem value={0}>Project</MenuItem>
                <MenuItem value={' '}>Kids Channels</MenuItem>
                <MenuItem value={'_crafty'}>Crafty Channels</MenuItem>
                <MenuItem value={'_deals'}>Deals Channels</MenuItem>
                <MenuItem value={'_games'}>Games Channels</MenuItem>
                <MenuItem value={'_noFace'}>NoFace Channels</MenuItem>
                <MenuItem value={'_sweedee'}>Sweedee Channels</MenuItem>
                <MenuItem value={'_tool_scroll'}>Tool Scroll Channels</MenuItem>
                <MenuItem value={'_test'}>Test</MenuItem>
            </Select>
            <TextField id="filled-basic" label="Video URL" variant="filled" onChange={e=>getVideoId(e.target.value)}/>
        </FormControl>  
        <Button variant="contained" onClick={e=> createAuthentication()}>Create Authentication</Button>
        {authUrl ? <div><a href={authUrl}>Authenticate with Google</a></div> : null}
    </Paper>
    )
}
