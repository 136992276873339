import {useRef, useEffect, useState }from 'react';
import{
    useNotify,
    useGetIdentity,
    useDataProvider,
    Title,
    usePermissions,
    Loading
}from 'react-admin';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

export const Prompts = () =>{
    const [value, setValue] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (search) =>{
        if(search.length > 2)
            setSearchTerm(search);
        else if(search.length < 2 && searchTerm.length > 0)
            setSearchTerm('');
    }
    const handleChange = (event, newValue) =>{
        setValue(newValue);
    }
    return(
        <Box sx={{flexGrow: 1}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Show Prompts" />
                            <Tab label="Add Prompt" />
                        </Tabs>
                        {value === 0 && 
                        <>
                        <Grid item xs ={12} sx={{margin:"10px"}}>
                                <TextField
                                    id="search"
                                    label="Search"
                                    defaultValue={searchTerm}
                                    variant="standard"
                                    onChange={(e)=>handleSearch(e.target.value)}
                                />
                            </Grid>
                        <ShowPrompts searchTerm = {searchTerm}/>
                        </>
                        }
                        {value === 1 && <AddPrompt/>}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

const ShowPrompts = ({searchTerm}) =>{
    const dataProvider = useDataProvider();
    const {permissions} = usePermissions()
    const notify = useNotify();
    const { data: identity, isLoading: identityLoading } = useGetIdentity()
    const [prompts, setPrompts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newTitle, setNewTitle] = useState('');
    const [newPrompt, setNewPrompt] = useState('');

    useEffect(() =>{
        if(identityLoading) return;
        setLoading(true);
        dataProvider.getList('prompts', { meta: { owner: identity.fullName, searchTerm:searchTerm } })
        .then(({data}) =>{
            const userPrompts = data.filter((prompt) => prompt.owner === identity.fullName);
            setPrompts(userPrompts);
        })
        .catch((error) =>{
            console.log(error);
        })
        .finally(() =>{
            setLoading(false);
        })
    }, [identity, searchTerm])

    const handleDeletePrompt = (id) =>{
        setLoading(true);
        dataProvider.delete('prompts', {id: id})
        .then(() =>{
            notify('Prompt Deleted', { type: 'success' })
            setPrompts(prompts.filter((prompt) => prompt.id !== id))
        })
        .catch((error) =>{
            notify('Could not delete Prompt!', { type: 'error' })
            console.log(error);
        }).finally(() =>{
            setLoading(false);
        })
    }

    const handleEditPrompt = (id, oldTitle, oldPrompt, isGlobal) =>{
        const updatedTitle = (newTitle === '') ? oldTitle : newTitle
        const updatedPrompt = (newPrompt === '') ? oldPrompt : newPrompt
        setLoading(true);
        dataProvider.update('prompts', {data: {id: id, title: updatedTitle, prompt: updatedPrompt, isGlobal: isGlobal}})
        .then(() =>{
            notify('Prompt Updated', { type: 'success' })
            setPrompts(prompts.map((prompt) => prompt.id === id ? {...prompt, title: updatedTitle, prompt: updatedPrompt} : prompt))
            setNewPrompt('');
            setNewTitle('');
        })
        .catch((error) =>{
            notify('Could not update Prompt!', { type: 'error' })
            console.log(error);
        }).finally(() =>{
            setLoading(false);
        })    
    }

    const handleSetIsGlobal = (prompt) =>{
        const isGlobal = !prompt.isGlobal;
        setLoading(true);
        dataProvider.update('prompts', {data: {id: prompt.id, title: prompt.title, prompt: prompt.prompt, isGlobal: isGlobal}})
        .then(() =>{
            notify('Prompt Updated', { type: 'success' })
            setPrompts(prompts.map((p) => p.id === prompt.id ? {...p, isGlobal: isGlobal} : p))
        })
        .catch((error) =>{
            notify('Could not update Prompt!', { type: 'error' })
            console.log(error);
        }).finally(() =>{
            setLoading(false);
        })
    }

    if(loading || identityLoading) return(<Loading/>)
    return(
        <Box sx={{flexGrow: 1}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper >
                        <Title title="Show Prompts"/>
                        <>
                            {prompts.map((prompt) =>{
                                return(
                                    <Grid container>
                                        <Grid item xs ={12} sx={{margin:"10px"}}>
                                        <TextField
                                            id="title"
                                            label="Title"
                                            defaultValue={prompt.title}
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e)=>setNewTitle(e.target.value)}
                                        />
                                           
                                        </Grid>
                                        <Grid item xs={12}>
                                        <TextField
                                            sx={{padding: "10px"}}
                                            id="prompt"
                                            label="Prompt"
                                            multiline
                                            fullWidth
                                            rows={4}
                                            defaultValue={prompt.prompt}
                                            variant="outlined"
                                            onChange={(e)=>setNewPrompt(e.target.value)}
                                        />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Stack spacing={1} direction="row" sx={{marginLeft:"10px"}}>
                                                <Button variant="contained" onClick={(e)=> handleEditPrompt(prompt.id, prompt.title, prompt.prompt, prompt.isGlobal)}>Edit</Button>
                                                <Button variant="contained" onClick={(e)=> handleDeletePrompt(prompt.id)}>Delete</Button>
                                                {permissions === 'superuser' && <FormControlLabel control={<Checkbox  checked = {prompt.isGlobal} onChange={(e)=> handleSetIsGlobal(prompt)}  size='large' color='success'/>} label="Share with Everyone" />}
                                            </Stack>
                                            <hr style={{margin:"10px"}}/>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

const AddPrompt = () =>{
    const dataProvider = useDataProvider();
    const {permissions} = usePermissions()
    const { data: identity, isLoading: identityLoading } = useGetIdentity()
    const notify = useNotify();
    const [prompt, setPrompt] = useState('');
    const [title, setTitle] = useState('');
    const [isGlobal, setIsGlobal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleAddPrompt = () =>{
        setLoading(true);
        dataProvider.create('prompts', {data: {title: title, prompt: prompt, owner: identity.fullName, isGlobal: isGlobal}})
        .then(({data}) =>{
            notify('Prompt Added', { type: 'success' })
        })
        .catch((error) =>{
            notify('Could not add Prompt!', { type: 'error' })
        })
        .finally(() =>{
            setLoading(false);
        })
    }
    if(loading || identityLoading) return(<Loading/>)
    return(
        <Box sx={{flexGrow: 1}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <Title title="Add Prompt"/>
                        <Stack spacing={2} sx={{width: 500}}>
                            <TextField
                                id="title"
                                label="Title"
                                defaultValue="Title"
                                variant="outlined"
                                onChange={(e)=>setTitle(e.target.value)}
                            />
                            <TextField
                                id="prompt"
                                label="Prompt"
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={(e)=>setPrompt(e.target.value)}
                            />
                            {permissions === 'superuser' && <FormControlLabel control={<Checkbox  checked = {isGlobal} onChange={(e)=> setIsGlobal(e.target.checked)} size='large' color='success'/>} label="Share with Everyone" />}
                            <Button 
                                variant="contained"
                                onClick={handleAddPrompt}
                            >
                                Add Prompt
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}