import axios from 'axios'
import endpoints from './endpoints.json'

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const translate = async(videos)=>{
    if(videos.length === 0) return []
    const strings = videos.map((video)=>video.title)
    const rawData = await axios.post(endpoints.backendUrl+endpoints.translateEndpoint, {strings:strings}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})  
    //console.log(rawData)
    const translatedVideos = videos.map((video, index)=>{return {...video, title: rawData.data.translations[index].translatedText}})
    return translatedVideos
    }