import React, { useState, useEffect } from 'react';
import { 
  Datagrid,  
  List,  
  Create,
  Edit,
  TextField,
  TextInput,
  required,
  ShowButton,
  ListButton,
  SimpleForm,
  SimpleShowLayout, 
  Show,
  useShowContext,
  TopToolbar,
  useGetList,
  useDataProvider,
  useRefresh,
  useRedirect,
  Confirm
   } from 'react-admin';
import Grid from '@mui/system/Unstable_Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

export const ProjectsList = () => {
  useEffect(()=>{
    const userEmail = localStorage.getItem('email')
    window.plausible('List Projects', {props: {user: userEmail}})
  },[])
  return(
  <List pagination={false} >
      <Datagrid bulkActionButtons={false}>
        <ShowButton/>
        <TextField source="name" />
        <TextField source="channels" />
      </Datagrid>
  </List>
);
}
export const ProjectsCreate = () => (
  <Create redirect="show">
        <SimpleForm>
            <TextInput source="projectName" label="Name of Project" validate={[required()]} fullWidth />
        </SimpleForm>
    </Create>
)

export const ProjectsShow = () => {

  useEffect(()=>{
    if (typeof window.plausible === 'function') {
      const userEmail = localStorage.getItem('email')
      window.plausible('Show Project', {props: {user: userEmail}})
    }
  },[])
  return (
    <Show title= {<Title/>}>
        <TopToolbar>
          <Buttons/>
          <ListButton />
        </TopToolbar>
        <SimpleShowLayout>
          <Content/>
        </SimpleShowLayout>
    </Show>
);
}
export const ProjectEdit = () => (
  <Edit redirect="show">
    <SimpleForm>
            <TextInput source="name" label="Name of Project" validate={[required()]} fullWidth />
        </SimpleForm>
  </Edit>
)
const Title = () => {
const record = useShowContext();
// the record can be empty while loading
if (!record) return null;
return <span>{record.name}</span>;
}

const Buttons = () =>{
  const {record} = useShowContext()
  const [open, setOpen] = useState(false)
  const [openChannels, setOpenChannels] = useState(false)
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const redirect = useRedirect();

  if(!record) return null
  const editProject =() =>{
    redirect(`/projects/${record.id}/edit`)
  }
  const deleteProject = () =>{
    setOpen(false)
    dataProvider.delete("projects", {projectsId:record.id, projectName: record.name.replaceAll(' ','_')}).then(({ data }) => {
      redirect('/projects');
    }).catch((error) => {
      console.log(error)
    })
  }
  const deleteProjectChannels = () =>{
    setOpenChannels(false)
    dataProvider.delete("projectChannels", {projectsId:record.id, projectName: record.name.replaceAll(' ','_')}).then(({ data }) => {
      refresh();
    }).catch((error) => {
      console.log(error)
    })
  }
  return<>
    <Button variant ="outlined" size="small" onClick={editProject}>Change Name</Button>
    <Button variant ="outlined" color ="error" size="small" onClick={(e)=>setOpenChannels(true)} >DELETE CHANNELS</Button>
    <Button variant ="contained" color ="error" size="small" onClick={(e)=>setOpen(true)} >DELETE PROJECT</Button>
    <Confirm
                isOpen={open}                
                title={`Delete Project ${record.name}`}
                content="Are you sure you want to delete this Project?"
                onConfirm={deleteProject}
                onClose={(e)=>setOpen(false)}
            />
    <Confirm
                isOpen={openChannels}               
                title={`Delete Channels from ${record.name}`}
                content="Are you sure you want to delete all channels of this Project?"
                onConfirm={deleteProjectChannels}
                onClose={(e)=>setOpenChannels(false)}
            />
  </>
}
const Content = () =>{
  const {record} = useShowContext()
  if (!record) return null;
  console.log(record)
  return <>
    <h3>Project Details</h3>
    <p>Name: {record.name} </p>
    <p>Channels: {record.channels}</p>
    <h3>Project Api Keys</h3>
    <ApiKeysList projectsId = {record.id} projectName = {record.name.replaceAll(' ','_')}/>
  </>
}
const ApiKeysList = ({projectsId, projectName}) =>{
  const {data} = useGetList('apiKeys',{meta:{projectsId:projectsId}})
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const [showAddKey,setShowAddKey] = useState(false)
  const [email,setEmail] = useState('')
  const [description,setDescription] = useState('')
  const [apiKey,setApiKey] = useState('')

  const removeApiKey = (id) =>{
    dataProvider.delete("apiKeys", {id:id}).then(({ data }) => {
      refresh()
    }).catch((error) => {
      console.log(error)
    })
  }
  const addApiKey = () =>{
    dataProvider.create('apiKeys', 
    { data: { projectsId:projectsId, projectName: projectName, 
        apiKeys:[{project:projectName,email:email,description:description,apiKey:apiKey}] } })
    .then(response => refresh()).catch(error => console.log(error));
    
  }
  if(!data) return null
  return <Paper elevation={3} sx={{padding:"5px"}}>
    {data.map(row=>(
      <Grid key={row.id} container spacing={2}>
        <Grid item xs={3}>
          <b>Email:</b> {row.email}
        </Grid>
        <Grid item xs={3}>
          <b>Description:</b> {row.description ? row.description : '-' }
        </Grid>
        <Grid item xs={4}>
          <b>apiKey:</b> {row.apiKey}
        </Grid>
        <Grid item xs={2}>
          <Button variant ="outlined" color ="error" size="small" onClick={(e)=>removeApiKey(row.id)}>Remove</Button>
        </Grid>
      </Grid>
    ))}
    {showAddKey ? <Grid container spacing={2}>
      <Grid item xs={3}>
        <input placeholder="Email" onChange={(e)=>setEmail(e.target.value)}/>
      </Grid>
      <Grid item xs={3}>
        <input placeholder="Description" onChange={(e)=>setDescription(e.target.value)}/>
      </Grid>
      <Grid item xs={4}>
        <input placeholder="apiKey" onChange={(e)=>setApiKey(e.target.value)}/>
      </Grid>
      <Grid item xs={2}>
        <Button variant ="outlined" size='small' onClick={(e)=>addApiKey()}>Add</Button>
      </Grid>
    </Grid> : <Button variant ="outlined" onClick={(e)=>setShowAddKey(true)}>Add Key</Button>}
  </Paper>
} 

