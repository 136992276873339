import { useEffect } from 'react';
import { 
  Datagrid,  
  List,  
  TextField,
  ShowButton
   } from 'react-admin';

export const OwnChannelList = () => {
  useEffect(()=>{
    if (typeof window.plausible === 'function') {
      const userEmail = localStorage.getItem('email')
      window.plausible('List OwnChannel', {props: {user: userEmail}})
    }
  },[])

  return (
  <List pagination={false} >
      <Datagrid bulkActionButtons={false}>
        <ShowButton/>
        <TextField source="name" />
        <TextField source="averageRetentionPercentage" />
        <TextField source="averageRetentionDuration" />
        <TextField source="averageViewsLast24h" label='Average Views last 24h' />
        <TextField source="averageCtr" label='Average Ctr' />
      </Datagrid>
  </List>
);
}
