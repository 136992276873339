import {useShowContext, useDataProvider } from 'react-admin';
import { Link } from 'react-router-dom'
import Grid from '@mui/system/Unstable_Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, {useState, useEffect} from "react";
import YouTube from 'react-youtube';
import {AddComparableVideoModal} from './AddCompareableVideoModal'


export const Content =({setReload, videoSeconds})=> {
    const dataProvider = useDataProvider()
    const [player, setPlayer] = useState("")
    const [comparableVideos, setComparableVideos] = useState([])
    const [update, setUpdate] = useState(0)
    const {record} = useShowContext()

    useEffect(()=>{
        (async() => {
        const data = await dataProvider.getComparableVideos(record.channelId)
        //console.log(data)
        if(data.data)
            setComparableVideos(data.data)
        })()          
    },[update])

    const deleteComparableVideo = async(video)=>{
        await dataProvider.deleteComparableVideo(record.channelId, video)
        const data = await dataProvider.getComparableVideos(record.channelId)
        setComparableVideos(data.data)
        setReload(Math.random())
    }
    if(record === null) return 
    const _onReady = async(event) =>{
        setPlayer(event.target)        
      }
    
    if(player !== "" && videoSeconds >0){
        player.seekTo(videoSeconds, true)
        player.playVideo();
        player.pauseVideo();
        console.log(`Seeking to: ${videoSeconds}`)
    }

    const opts = {
    height: '180',
    width: '320',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
    },
    };
    if(record.videoId === "")
        return <></>
    return( 
        <>
            <Paper elevation={3} style={{ minHeight:"190px", padding:"3px"}}>
                <Grid container spacing={2} >
                    <Grid xs={3} >
                        <div style={{backgroundColor:"#355C7D", margin:"2px",paddingTop:"5px", textAlign:"center"}}>
                        <YouTube videoId={record.videoId} opts={opts} onReady={_onReady}/>   
                        </div> 
                    </Grid> 
                    <Grid xs={9}>
                        <VideoStats/>
                    </Grid> 
                </Grid>
            </Paper>
            <h4>Compare Videos</h4>
            <Grid container spacing={2} >
                <Grid xs={3}>
                    <Card sx={{ maxWidth: 345, backgroundColor:"#F86F15" }}>
                        <CardMedia
                            sx={{ height: 190, textAlign:"center", marginTop:"5px" }}
                            >
                                <ComparableYoutubePlayer videoSeconds={videoSeconds} videoData = {comparableVideos[0]} />
                                </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="b" component="div">
                            {comparableVideos[0]?.video?.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <Grid container spacing={2} >
                                <Grid xs={12}><b>{comparableVideos[0]?.video?.publishDate}</b></Grid>
                            </Grid>
                            <Grid container spacing={2} >
                            {comparableVideos?.length >= 1 ? 
                                <>
                                <Grid xs={6}><b>Views:</b> {comparableVideos[0]?.video?.views}</Grid>
                                <Grid xs={6}><b>Views 2h:</b> {comparableVideos[0]?.video?.viewsLast2h}</Grid>
                                </>
                                : <></>}
                            </Grid>
                            <Grid container spacing={2} >
                            {comparableVideos?.length >= 1 ? 
                                <>
                                <Grid xs={6}><b>Retention 3 min:</b> {comparableVideos[0]?.video?.retentionPercentageFirst3Min}%</Grid>
                                <Grid xs={6}><b>Retention Score:</b> {comparableVideos[0]?.video?.weightedRetentionPercentageFirstMin}</Grid>
                                </>
                                : <></>}
                            </Grid>
                            </Typography>
                        </CardContent>
                        <CardActions>
                        {comparableVideos?.length >= 1 ?
                            <>
                            <Button size="small" onClick={e=> setReload(Math.random())}><Link sx={{color:"#4081C8"}} to={`/ownVideos/${comparableVideos[0].video.id}/show`}><VisibilityIcon sx={{color:"#ffffff"}}/></Link></Button>
                            <Button sx={{color:"#ffffff"}} size="small" onClick={e=> deleteComparableVideo(comparableVideos[0].video)}>Remove from List</Button>
                            </>
                            :<AddComparableVideoModal channelId = {record.ownedChannelsId} setReload ={setReload} setUpdate={setUpdate}/>}
                        </CardActions>
                    </Card>    
                </Grid>        
            
                <Grid xs={3}>
                    <Card sx={{ maxWidth: 345, backgroundColor:"#FAC218" }}>
                        <CardMedia
                            sx={{ height: 190, textAlign:"center", marginTop:"5px" }}
                            >
                                <ComparableYoutubePlayer videoSeconds={videoSeconds} videoData = {comparableVideos[1]} />
                                </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="b" component="div">
                            {comparableVideos[1]?.video?.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <Grid container spacing={2} >
                                <Grid xs={12}><b>{comparableVideos[1]?.video?.publishDate}</b></Grid>
                            </Grid>
                            <Grid container spacing={2} >
                                {comparableVideos?.length >= 2 ? 
                                <>
                                <Grid xs={6}><b>Views:</b> {comparableVideos[1]?.video?.views}</Grid>
                                <Grid xs={6}><b>Views 2h:</b> {comparableVideos[1]?.video?.viewsLast2h}</Grid>
                                </>
                                : <></>}
                            </Grid>
                            <Grid container spacing={2} >
                            {comparableVideos?.length >= 2 ?
                                <>
                                <Grid xs={6}><b>Retention 3 min:</b> {comparableVideos[1]?.video?.retentionPercentageFirst3Min}%</Grid>
                                <Grid xs={6}><b>Retention Score:</b> {comparableVideos[1]?.video?.weightedRetentionPercentageFirstMin}</Grid>
                                </>
                                : <></>}
                            </Grid>
                            </Typography>
                        </CardContent>
                        <CardActions>
                        {comparableVideos?.length >= 2 ?
                            <>
                            <Button size="small" onClick={e=> setReload(Math.random())}><Link sx={{color:"#4081C8"}} to={`/ownVideos/${comparableVideos[1].video.id}/show`}><VisibilityIcon sx={{color:"#ffffff"}}/></Link></Button>
                            <Button sx={{color:"#ffffff"}} size="small" onClick={e=> deleteComparableVideo(comparableVideos[1].video)}>Remove from List</Button>
                            </>
                            :<AddComparableVideoModal channelId = {record.ownedChannelsId} setReload ={setReload} setUpdate={setUpdate}/>}
                        </CardActions>
                    </Card>
                </Grid>
                <Grid xs={3}>
                <Card sx={{ maxWidth: 345, backgroundColor:"#DEB3AD" }}>
                        <CardMedia
                            sx={{ height: 190, textAlign:"center", marginTop:"5px" }}
                            >
                                <ComparableYoutubePlayer videoSeconds={videoSeconds} videoData = {comparableVideos[2]} />
                                </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="b" component="div">
                            {comparableVideos[2]?.video?.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <Grid container spacing={2} >
                                <Grid xs={12}><b>{comparableVideos[2]?.video?.publishDate}</b></Grid>
                            </Grid>
                            <Grid container spacing={2} >
                            {comparableVideos?.length >= 3 ? 
                                <>
                                <Grid xs={6}><b>Views:</b> {comparableVideos[2]?.video?.views}</Grid>
                                <Grid xs={6}><b>Views 2h:</b> {comparableVideos[2]?.video?.viewsLast2h}</Grid>
                                </>
                                : <></>}
                            </Grid>
                            <Grid container spacing={2} >
                            {comparableVideos?.length >= 3 ? 
                                <>
                                <Grid xs={6}><b>Retention 3 min:</b> {comparableVideos[2]?.video?.retentionPercentageFirst3Min}%</Grid>
                                <Grid xs={6}><b>Retention Score:</b> {comparableVideos[2]?.video?.weightedRetentionPercentageFirstMin}</Grid>
                                </>
                                : <></>}
                            </Grid>
                            </Typography>
                        </CardContent>
                        <CardActions>
                        {comparableVideos?.length >= 3 ?
                            <>
                            <Button size="small" onClick={e=> setReload(Math.random())}><Link sx={{color:"#4081C8"}} to={`/ownVideos/${comparableVideos[2].video.id}/show`}><VisibilityIcon sx={{color:"#ffffff"}}/></Link></Button>
                            <Button size="small" sx={{color:"#ffffff"}} onClick={e=> deleteComparableVideo(comparableVideos[2].video)}>Remove from List</Button>
                            </>
                            :<AddComparableVideoModal channelId = {record.ownedChannelsId} setReload ={setReload} setUpdate={setUpdate}/>}
                        </CardActions>
                    </Card> 
                </Grid>
                <Grid xs={3}>
                <Card sx={{ maxWidth: 345, backgroundColor:"#F51663" }}>
                        <CardMedia
                            sx={{ height: 190, textAlign:"center", marginTop:"5px" }}
                            >
                                <ComparableYoutubePlayer videoSeconds={videoSeconds} videoData = {comparableVideos[3]} />
                                </CardMedia>
                        <CardContent>
                            <Typography gutterBottom variant="b" component="div">
                            {comparableVideos[3]?.video?.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            <Grid container spacing={2} >
                                <Grid xs={12}><b>{comparableVideos[3]?.video?.publishDate}</b></Grid>
                            </Grid>
                            <Grid container spacing={2} >
                            {comparableVideos?.length === 4 ? 
                                <>
                                <Grid xs={6}><b>Views:</b> {comparableVideos[3]?.video?.views}</Grid>
                                <Grid xs={6}><b>Views 2h:</b> {comparableVideos[3]?.video?.viewsLast2h}</Grid>
                                </>
                                : <></>}
                            </Grid>
                            <Grid container spacing={2} >
                            {comparableVideos?.length === 4 ? 
                                <>
                                <Grid xs={6}><b>Retention 3 min:</b> {comparableVideos[3]?.video?.retentionPercentageFirst3Min}%</Grid>
                                <Grid xs={6}><b>Retention Score:</b> {comparableVideos[3]?.video?.weightedRetentionPercentageFirstMin}</Grid>
                                </>
                                : <></>}
                            </Grid>
                            </Typography>
                        </CardContent>
                        <CardActions>
                        {comparableVideos?.length === 4 ?
                            <>
                            <Button size="small" onClick={e=> setReload(Math.random())}><Link to={`/ownVideos/${comparableVideos[3].video.id}/show`}><VisibilityIcon sx={{color:"#ffffff"}}/></Link></Button>
                            <Button size="small" sx={{color:"#ffffff"}} onClick={e=> deleteComparableVideo(comparableVideos[3].video)}>Remove from List</Button>
                            </>
                            :<AddComparableVideoModal channelId = {record.ownedChannelsId} setReload ={setReload} setUpdate={setUpdate}/>}
                        </CardActions>
                    </Card> 
                </Grid>
            </Grid>
        </>
    )
}

const VideoStats = () => {
    const {record} = useShowContext()
    const dataProvider = useDataProvider()
    const [averageRetention, setAverageRetention] = useState(0)
    const [averageRetentionFirstThirtySeconds, setAverageRetentionFirstThirtySeconds] = useState(0)
    const [averageweightedRetentionPercentageFirstMin, setAverageweightedRetentionPercentageFirstMin] = useState(0)
    const [averageRetentionPercentageFirst3Minutes, setAverageRetentionPercentageFirst3Minutes] = useState(0)
    const [averageCtr, setAverageCtr] = useState(0)
    const [averageRetentionSnapshot, setAverageRetentionSnapshot] = useState(0)
    const [averageRetentionFirstThirtySecondsSnapshot, setAverageRetentionFirstThirtySecondsSnapshot] = useState(0)
    const [averageweightedRetentionPercentageFirstMinSnapshot, setAverageweightedRetentionPercentageFirstMinSnapshot] = useState(0)
    const [averageRetentionPercentageFirst3MinutesSnapshot, setAverageRetentionPercentageFirst3MinutesSnapshot] = useState(0)
    const [threshold, setThreshold] = useState(0)

    useEffect(()=>{
        if(record === null) return
        dataProvider.getChannelAverage('ownChannels', {channelId:record?.ownedChannelsId}).then(result=>{
            setAverageRetention(result.data.averageRetentionPercentage)
            setAverageRetentionFirstThirtySeconds(result.data.averageRetentionFirstThirtySeconds)
            setAverageweightedRetentionPercentageFirstMin(result.data.averageWeightedRetentionFirstMinute)
            setAverageRetentionPercentageFirst3Minutes(result.data.averageRetentionPercentageFirst3Minutes)
            setAverageCtr(result.data.averageCtr)
            setAverageRetentionSnapshot(result.data.averageRetentionPercentageSnapshot)
            setAverageRetentionFirstThirtySecondsSnapshot(result.data.averageRetentionFirstThirtySecondsSnapshot)
            setAverageweightedRetentionPercentageFirstMinSnapshot(result.data.averageWeightedRetentionFirstMinuteSnapshot)
            setAverageRetentionPercentageFirst3MinutesSnapshot(result.data.averageRetentionPercentageFirst3Min_snapshot)
            setThreshold(result.data.retentionSnapshotThreshold?.replace(",000","k"))
        }).catch()
    },[record])

    if (!record) return null;

    return (
        <Grid container spacing={4} sx={{marginTop:"10px"}} >
            <Grid xs={3}>
                <Grid xs={12}>
                    <b>Publish Date: </b> {record.publishDate?.replace('T', ' ').split('.')[0]}
                </Grid>
                <Grid xs={12}>
                    <b>Duration: </b> {record.duration}
                </Grid>
                <Grid xs={12}>
                    <b>Views: </b> {record.views !== null ? record.views?.toLocaleString() : 0}
                </Grid>
                <Grid xs={12}>
                    <b>Views in the last 24 hours: </b> {record.views_1d !== null ? record.viewsLast24hSum?.toLocaleString() : 0}
                </Grid>
                <Grid xs={12}>
                    <b>Views last 2 hours: </b> {record.viewsLast2h}
                </Grid>
                <Grid xs={12}>
                    <b>Likes: </b> {record.likes}
                </Grid>
            </Grid> 
            <Grid xs={4}>
                <Grid xs={12} sx={{color: record.retentionPercentage < 0.2 ? 'grey' :(record.retentionPercentage > averageRetention ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Retention Percentage: </b>{record.retentionPercentage}% {showDiff(record.retentionPercentage, averageRetention)}
                </Grid>
                <Grid xs={12}>
                    <b>Retention Duration: </b> {record.retentionDuration}
                </Grid>
                <Grid xs={12} sx={{color: record.retentionPercentageFirst3Min < 0.2 ? 'grey' :(record.retentionPercentageFirst3Min > averageRetentionPercentageFirst3Minutes ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Retention 3 Minutes: </b> {record.retentionPercentageFirst3Min}% {showDiff(record.retentionPercentageFirst3Min, averageRetentionPercentageFirst3Minutes)}
                </Grid>
                <Grid xs={12} sx={{color: record.retentionFirstThirtySeconds < 0.2 ? 'grey' :(record.retentionFirstThirtySeconds > averageRetentionFirstThirtySeconds ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Retention 30 Seconds: </b> {record.retentionFirstThirtySeconds}% {showDiff(record.retentionFirstThirtySeconds, averageRetentionFirstThirtySeconds)}
                </Grid>
                <Grid xs={12} sx={{color: record.weightedRetentionPercentageFirstMin < 0.2 ? 'grey' :(record.weightedRetentionPercentageFirstMin > averageweightedRetentionPercentageFirstMin ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Retention Score: </b> {record.weightedRetentionPercentageFirstMin} {showDiff(record.weightedRetentionPercentageFirstMin, averageweightedRetentionPercentageFirstMin)}
                </Grid>
                <Grid xs={12} sx={{color: record.ctr < 0.2 ? 'grey' :(record.ctr > averageCtr ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Click Through Rate: </b> {record.ctr} {showDiff(record.ctr, averageCtr)}
                </Grid>
            </Grid> 
            <Grid xs={5}>
                <Grid xs={12} sx={{color: record.retentionPercentage_snapshot < 0.2 ? 'grey' :(record.retentionPercentage_snapshot > averageRetentionSnapshot ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Retention Percentage at {threshold}: </b> {record.retentionPercentage_snapshot}% {showDiff(record.retentionPercentage_snapshot, averageRetentionSnapshot)}
                </Grid>
                <Grid xs={12}> - </Grid>
                <Grid xs={12} sx={{color: record.retentionPercentageFirst3Min_snapshot < 0.2 ? 'grey' :(record.retentionPercentageFirst3Min_snapshot >= averageRetentionPercentageFirst3MinutesSnapshot ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Retention 3 Minutes at {threshold}: </b> {record.retentionPercentageFirst3Min_snapshot}% {showDiff(record.retentionPercentageFirst3Min_snapshot, averageRetentionPercentageFirst3MinutesSnapshot)}
                </Grid>
                <Grid xs={12} sx={{color: record.retentionFirstThirtySeconds_snapshot < 0.2 ? 'grey' :(record.retentionFirstThirtySeconds_snapshot > averageRetentionFirstThirtySecondsSnapshot ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Retention 30 Seconds at {threshold}: </b> {record.retentionFirstThirtySeconds_snapshot}% {showDiff(record.retentionFirstThirtySeconds_snapshot, averageRetentionFirstThirtySecondsSnapshot)}
                </Grid>
                <Grid xs={12} sx={{color: record.weightedRetentionPercentageFirstMin_snapshot < 0.2 ? 'grey' :(record.weightedRetentionPercentageFirstMin_snapshot > averageweightedRetentionPercentageFirstMinSnapshot ? 'green' : 'red')}}>
                    <b style={{color:"black"}}>Retention Score at {threshold}: </b> {record.weightedRetentionPercentageFirstMin_snapshot} {showDiff(record.weightedRetentionPercentageFirstMin_snapshot, averageweightedRetentionPercentageFirstMinSnapshot)}
                </Grid>
                <Grid xs={12}>
                    <b>Click Through Rate at {threshold}: </b> {record.ctr_snapshot}
                </Grid>
            </Grid>
        </Grid>                     
        )
    }
const showDiff = (value, average) => {
    if(value ===  null ||  value === 0 || average === null || average === 0) return
    if(value >= average) return <span style={{color:"green", fontSize:"small"}}>({Math.round((value - average)*100)/100} above avg)</span>
    if(value < average) return <span style={{color:"red", fontSize:"small"}}>({Math.round((value - average)*100)/100} below avg)</span>
    return <span style={{color:"grey"}}>-</span>
}
export const ComparableYoutubePlayer =({videoSeconds, videoData})=> {
    
    const [player, setPlayer] = useState("")
    if(videoData === undefined || videoData.video === undefined ) return <></>
    const _onReady = (event) =>{
        setPlayer(event.target)
      }
    
    if(player !== "" && videoSeconds >0){
        player.seekTo(videoSeconds, true)
        player.playVideo();
        player.pauseVideo();
    }

    const opts = {
    height: '180',
    width: '320',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
    },
    };
    if(videoData.video.videoId === "")
        return <></>
    return <YouTube videoId={videoData.video.videoId} opts={opts} onReady={_onReady} />       
}