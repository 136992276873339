import {
    useDataProvider, 
    Title} from 'react-admin';
import Grid from '@mui/system/Unstable_Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, {useRef,useEffect, useState} from "react";
import { SelectChannel } from '../miscellanious/utils';
import {Chart, LineElement,LineController, LinearScale, CategoryScale, PointElement, Tooltip, Filler} from 'chart.js'
Chart.register(LineElement,LineController, LinearScale, CategoryScale, PointElement, Tooltip, Filler)
let myChart = null

 export const VideoPerformances = () => {
  //const [channel, setChannel] = useState(1);
  const [channel, setChannel] = useState({id: 1, name: 'Toys and Colors', project: '_kids'})

  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState("weightedRetentionPercentageFirstMin");
  const [publishDate, setPublishdate] = useState(90);
  const [videoCreator, setVideoCreator] = useState('')
  const [threshold, setThreshold] = useState(0)

  useEffect(()=>{
    if (typeof window.plausible === 'function') {
      const userEmail = localStorage.getItem('email')
      window.plausible('Show Video Performances', {props: {user: userEmail}})
    }
  },[])
        return (
            <>
              <Title title="Video Performances" />
              <Filters
                channel = {channel} setChannel ={setChannel}
                searchTerm = {searchTerm} setSearchTerm ={setSearchTerm}
                filter ={filter} setFilter ={setFilter}
                publishDate = {publishDate} setPublishdate = {setPublishdate}
                videoCreator = {videoCreator} setVideoCreator ={setVideoCreator}
                threshold = {threshold}
              />
              <Content channel = {channel} searchTerm = {searchTerm} filter ={filter} publishDate = {publishDate} videoCreator = {videoCreator} setThreshold={setThreshold}/>
            </>
        );
}
const Filters = ({channel, setChannel, filter, setFilter, searchTerm, setSearchTerm, publishDate, setPublishdate, videoCreator, setVideoCreator, threshold}) =>{
    return (
        <Grid container spacing={2} >
          <Grid xs={3} sx={{paddingTop:"0px"}}>
                <Search searchTerm ={searchTerm} setSearchTerm={setSearchTerm}/>
            </Grid>
            <Grid xs={2}>
                <SelectChannel channel ={channel} setChannel={setChannel}/>
            </Grid>
            <Grid xs={2}>
                <SelectFilter filter = {filter} threshold={threshold} setFilter={setFilter}/>
            </Grid>
            <Grid xs={2}>
                <SelectPublishDate publishDate={publishDate} setPublishdate={setPublishdate}/>
            </Grid>
            <Grid xs={2}>
                <SelectVideoCreator videoCreator={videoCreator} setVideoCreator={setVideoCreator}/>
            </Grid> 
        </Grid>
    )

}
 
const SelectFilter = ({filter, threshold, setFilter}) => {
    const handleChange = (event) => {
      setFilter(event.target.value);
    };
  
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filter</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            label="Filter"
            onChange={handleChange}
          >
            <MenuItem value={"retentionPercentage"}>Retention</MenuItem>
            <MenuItem value={"retentionPercentageFirst3Min"}>Retention First 3 Minutes</MenuItem>
            <MenuItem value={"retentionFirstThirtySeconds"}>Retention First 30 Seconds</MenuItem>
            <MenuItem value={"weightedRetentionPercentageFirstMin"}>Retention Score</MenuItem>
            <MenuItem value={"ctr"}>Click Through Rate</MenuItem>
            <MenuItem value={"viewsLast2h"}>Views Last 2 Hours</MenuItem>
            <MenuItem value={"viewsLast24hSum"}>Views Last 24 Hours</MenuItem>
            <MenuItem value={"retentionPercentage_snapshot"}>Retention at {threshold}</MenuItem>
            <MenuItem value={"retentionPercentageFirst3Min_snapshot"}>Retention First 3 Minutes at {threshold}</MenuItem>
            <MenuItem value={"retentionFirstThirtySeconds_snapshot"}>Retention First 30 Seconds at {threshold}</MenuItem>
            <MenuItem value={"weightedRetentionPercentageFirstMin_snapshot"}>Retention Score at {threshold}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }   
  const Search = ({searchTerm, setSearchTerm}) => {
    const handleChange = (event) => {
      const text = event.target.value
      if(text.length > 2 || text.length === 0)
        setSearchTerm(text);
    };
  
    return (
      <Box>
        <FormControl fullWidth>
          <TextField label ="Search" defaultValue={searchTerm}  onChange={handleChange} variant="filled" />
        </FormControl>
      </Box>
    );
  }
  const SelectPublishDate = ({publishDate, setPublishdate}) => {
    const handleChange = (event) => {
        setPublishdate(event.target.value);
    };
  
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Publish date</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={publishDate}
            label="Publish Date"
            onChange={handleChange}
          >
            <MenuItem value={30}>Last 30 days</MenuItem>
            <MenuItem value={90}>Last 90 days</MenuItem>
            <MenuItem value={180}>Last 6 month</MenuItem>
            <MenuItem value={365}>Last 12 month</MenuItem>
            <MenuItem value={10000}>All Time</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }

  const SelectVideoCreator = ({videoCreator, setVideoCreator}) => {
    const dataProvider = useDataProvider();
    const[users, setUsers] = useState([])

    useEffect(() => {   
            (async () => {
                const userData = await dataProvider.getList('userData', {pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}})
                setUsers(userData.data.map(user => ({id: user.id, label: user.name})))
            })()     
    }, [])

    return (
        <Autocomplete
          disablePortal
          options={users}
          value={videoCreator}
            onChange={(event, newValue) => {
                //console.log(newValue)
                setVideoCreator(newValue);
            }}
          renderInput={(params) => <TextField {...params} label="Video Creator" />}
        />
      )
}
const Content = ({channel,searchTerm, filter, publishDate, videoCreator, setThreshold}) =>{
    const dataProvider = useDataProvider();
    const [bestVideos, setBestVideos] = useState([])
    const [worstVideos, setWorstVideos] = useState([])
    const [channelAverage, setChannelAverage] = useState({averageRetention:[]})
    const [selectedVideo, setSelectedVideo] = useState(null)

    useEffect(() => {
         (async () => {
             const bestVideos = await dataProvider.getList("ownVideos",
                                                            {pagination:{page:0,perPage:10},
                                                             filter:{Channel:channel.id,
                                                                    publishDate:publishDate,creatorSearch:videoCreator === null ? '' : videoCreator.label,
                                                                    searchPhrase:searchTerm},
                                                             sort:{field:filter, order:"DESC"}
                                                            })
             let data = cleanData(bestVideos.data, filter)                                                        
             setBestVideos(data)
             setSelectedVideo(data[0])
             const worstVideos = await dataProvider.getList("ownVideos",
                                                            {pagination:{page:0,perPage:10},
                                                             filter:{Channel:channel.id,
                                                                    publishDate:publishDate,creatorSearch:videoCreator === null ? '' : videoCreator.label,
                                                                    searchPhrase:searchTerm},
                                                             sort:{field:filter, order:"ASC"}
                                                            })
             data = cleanData(worstVideos.data, filter)
             setWorstVideos(data)
             data = await dataProvider.getChannelAverage('ownChannels', {channelId:channel.id})
             setChannelAverage(data.data)
             setThreshold(data.data.retentionSnapshotThreshold)
         //console.log(bestVideos.data)
            })()
    }, [channel,searchTerm, filter, publishDate, videoCreator])

    return (
      <Grid container spacing={2} sx={{marginTop:"3px"}}>
          <Grid xs={6}>  
            <Paper elevation={3} sx={{paddingLeft:"3px"}}>    
              <Grid container spacing={2} >
                  <Grid xs={4}><b>Top 5 Videos</b></Grid>
              </Grid>
              {bestVideos.map((video, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid xs={2}><img className='mousePointer' src={video.thumbnail} height="60px" onClick={e => setSelectedVideo(video)}/></Grid>
                  <Grid xs={8}>{video.title}</Grid>
                  <Grid xs={2}>{video[filter]}</Grid>
                </Grid>
              ))}
              <hr/>
              <Grid container spacing={2} >
                <Grid xs={4}><b>Bottom 5 Videos</b></Grid>
              </Grid>
              {worstVideos.map((video, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid xs={2}><img className='mousePointer' src={video.thumbnail} height="60px" onClick={e => setSelectedVideo(video)}/></Grid>
                  <Grid xs={8}>{video.title}</Grid>
                  <Grid xs={2}>{video[filter]}</Grid>
                </Grid>
              ))}
            </Paper>
          </Grid>
        <Grid xs={6}>
          <Paper elevation={3} sx={{padding:"3px"}}>
            <VideoDetail video = {selectedVideo} channelAverage = {channelAverage}/>
          </Paper>
          </Grid>
      </Grid>
    )
}

const VideoDetail = ({video, channelAverage}) =>{
  //console.log(video)
  if(!video) return <></>
  return(
    <Grid container spacing={2} sx={{marginTop:"3px"}} alignItems="center" textAlign='center'>
      <Grid xs={12}>
        <YoutubeEmbed embedId={video ? video.videoId : ''}/>
      </Grid>
      <Grid xs={2}>Date</Grid>
      <Grid xs={2}>Views</Grid>
      <Grid xs={2}>Retention</Grid>
      <Grid xs={2}>at 3 Min</Grid>
      <Grid xs={2}>at 30 Sec</Grid>
      <Grid xs={1}>Score</Grid>
      <Grid xs={1}>CTR</Grid>
      <Grid xs={2}>{video?.publishDate}</Grid>
      <Grid xs={2}>{video?.views}</Grid>
      <Grid xs={2}>{video?.retentionPercentage}%</Grid>
      <Grid xs={2}>{video?.retentionPercentageFirst3Min}%</Grid>
      <Grid xs={2}>{video?.retentionPercentageFirst30Sec}%</Grid>
      <Grid xs={1}>{video?.weightedRetentionPercentageFirstMin}</Grid>
      <Grid xs={1}>{video?.ctr ? video.ctr : 'not set'}</Grid>
      <Grid xs={12}>
        {video.retention && video.retention.length > 0 ? <ViewChart record={video} channelAverage={channelAverage}/> : <></>}
      </Grid>
    </Grid>
  )
}

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="640"
      height="360"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);
const ViewChart =({record, channelAverage})=>{
  const ctx = useRef()

  useEffect(()=>{
    loadChart()
    return () => {
      if (myChart != null) {
          myChart.destroy();
      }
    }
})
const loadChart = async ()=>{
  if (!record || ctx.current === null) return <></>;

  Tooltip.positioners.bottom = function(items, eventPosition) {      
      const chart = this.chart;
    
      return {
        x: chart.chartArea.left,
        y: chart.chartArea.bottom,
        xAlign: 'center',
        yAlign: 'bottom',
      };
    };
    let labels = []
    for (let inc=0; inc<record.retention.length*5;inc+=5){
        const paramName = secondsToHms(inc)
        labels.push(paramName)
    }
  let viewData = record.retention.map(value => value > 2 ? 2 : value)
  //viewData[0] = viewData[0]  > 2 ? 2 : viewData[0]
  
  if (myChart != null) {
      myChart.destroy();
  }
  myChart = new Chart(ctx.current, {
      type: 'line',
      data: {
          labels: labels,
          datasets: [{
            label: record.title,
            data: viewData,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            pointStyle: 'circle',
            pointRadius: 4,
            pointHoverRadius: 8,
            tension: 0
          },
          {
            label: 'Latest 10 Videos',
            data: channelAverage.averageRetention,
            fill: true,
            borderColor: 'rgb(211,211,211)',
            pointStyle: 'circle',
            pointRadius: 4,
            pointHoverRadius: 8,
            tension: 0
        }
      ]
      },
      options: {
          interaction: {
              intersect: false,
              mode: 'index',
          }
      }
  })
}
  return <div><canvas ref={ctx} width="300" height="100" ></canvas></div>
}
// change seconds to hh:mm:ss
const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);
}
const cleanData = (data, filter) =>{
  //delete all entries with views = 0 or viewsLast24hSum = 0  or viewsLast2h = 0 and shorten list to 5 entries max
  let cleanedData = []
  let counter = 0
  data.forEach(video => {
    
    if(filter === "retentionPercentage_snapshot" || filter === "retentionPercentageFirst3Min_snapshot" || filter === "weightedRetentionPercentageFirstMin_snapshot"){      
      if(video.retentionPercentage_snapshot !== 0 && counter < 5){
        cleanedData.push(video)
        counter++
      }
      else
      console.log("bla",video)
    }
    else{
      if(video.views !== '0' && video.viewsLast24hSum !== '0' && video.viewsLast2h !== '0' && !video.viewsLast2h.includes('-') && counter < 5){
        cleanedData.push(video)
        counter++
      }
      else
      console.log(video)
    }
  }
  )
  return cleanedData
}