import axios from 'axios'
import { HttpError } from 'react-admin';
import endpoints from './endpoints.json'

export const getTikTokUsers = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order,
        project: params.filter.project
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.tikTokUsers, { params: queryParams})
    //console.log(rawData)
    const mappedData = rawData.data.map(entry =>
        ({
            id: entry.id,
            name: entry.name,
            secUid: entry.secUid,
            thumbnail: entry.thumbnail,
            followerCount: entry.followerCount?.toLocaleString(),
            heartCount: entry.heartCount?.toLocaleString(),
            uniqueId: '@'+entry.uniqueId,
            project:entry.project
        }))
    return {data:mappedData,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: rawData.length < perPage ? false : true}
    }
}

export const createTikTokUser = async(params)=>{
    
    if(params.data.project === '')
        return Promise.reject(new HttpError(
            "No Project selected",
            404,
            {}
        ))
    const userNames = params.data.userNames.split(/\r?\n/)
    
    const mappedParams = {
        project: params.data.project,
        userNames: userNames

    }
    const rawData = await axios.post(endpoints.backendUrl+endpoints.tikTokUsers ,mappedParams)
    console.log(rawData)
   const mappedData = rawData.data.map(entry =>
    ({
        id: entry.id,
        name: entry.name,
        secUid: entry.secUid,
        thumbnail: entry.thumbnail,
        followerCount: entry.followerCount?.toLocaleString(),
        heartCount: entry.heartCount?.toLocaleString(),
        uniqueId: '@'+entry.uniqueId,
        project:entry.project
    }))
    return {data:mappedData[0]}
}
export const deleteTikTokUser = async(params)=>{
    const data = params.previousData
    await axios.delete(endpoints.backendUrl+endpoints.tikTokUsers, { params: {ids:params.id, project:data.project}})
    return {data:data}
}
export const deleteTikTokUsers = async(project, ids)=>{
    await axios.delete(endpoints.backendUrl+endpoints.tikTokUsers, { params: {ids: ids.join(), project:project}})
    return {data:ids}
}

export const getTikTokChallenges = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order,
        project: params.filter.project
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.tikTokChallenges, { params: queryParams})
    console.log(rawData)
    const mappedData = rawData.data.map(entry =>
        ({
            id: entry.id,
            title: entry.title,
            description: entry.description,
            videoCount: entry.videoCount?.toLocaleString(),
            viewCount: entry.viewCount?.toLocaleString(),
            project:entry.project
        }))
    return {data: mappedData,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: mappedData.length < perPage ? false : true}
    }
}

export const getTikTokVideos = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field === undefined ? 'averageViewsPerDay' : params.sort.field,
        order: params.sort.order,
        project: params.filter.project
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.tikTokVideos, { params: queryParams})
    //console.log(rawData)
    const mappedData = rawData.data.map(entry => {
        const date = new Date(entry.createTime*1000)
        const dateFormat = date.toDateString();
        return ({
            id: entry.id,
            authorName: entry.authorName,
            secUid: entry.secUid,
            uniqueId: entry.uniqueId,
            thumbnail: entry.thumbnail,
            dynamicThumbnail: entry.dynamicThumbnail,
            url: entry.url,
            musicUrl: entry.musicUrl,
            duration: entry.duration + " sec",
            createTime: dateFormat,
            description: entry.description,
            views: entry.views?.toLocaleString(),
            averageViewsPerDay: entry.averageViewsPerDay?.toLocaleString(),
            heartCount: entry.heartCount?.toLocaleString(),
            hashTags: JSON.parse(entry.hashTags),
            project:entry.project
        })})
    return {data: mappedData,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: rawData.length < perPage ? false : true}
    }
}
export const getTikTokVideo = async(params)=>{

    const rawData = await axios.get(endpoints.backendUrl+endpoints.tikTokVideos+`/${params.id}`)
    //console.log(rawData)
    const mappedData = rawData.data.map(entry => {
        const date = new Date(entry.createTime*1000)
        const dateFormat = date.toDateString();
        return ({
            id: entry.id,
            authorName: entry.authorName,
            secUid: entry.secUid,
            uniqueId: entry.uniqueId,
            thumbnail: entry.thumbnail,
            dynamicThumbnail: entry.dynamicThumbnail,
            url: entry.url,
            createTime: dateFormat,
            description: entry.description,
            views: entry.views?.toLocaleString(),
            heartCount: entry.heartCount?.toLocaleString(),
            hashTags: entry.hashTags?.replaceAll('[','').replaceAll(']',''),
            project:entry.project
        })
    })
    return {data:mappedData[0]}    
}