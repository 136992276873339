import { useEffect } from 'react';
import { 
  Datagrid,  
  List,  
  TextField,
  useRecordContext
   } from 'react-admin';

export const OwnShortList = () => {
  useEffect(()=>{
    if (typeof window.plausible === 'function') {
      const userEmail = localStorage.getItem('email')
      window.plausible('List Own Shorts', {props: {user: userEmail}})
    }
  },[])

  return (
  <List pagination={false} >
      <Datagrid bulkActionButtons={false}>
        <Thumbnail/>
        <Link source="title" label = 'Title'/>
        <TextField source='publish_date' label = 'Published' />
        <TextField source="views" />
        <TextField source="views_last_2h" />
        <TextField source="views_last_24h" label='Views last 24h' />
        <TextField source="retention_percentage" label='Retention' />
        <TextField source="retention_percentage_first_ten_seconds" label='Retention at 10 sec' />
        <TextField source="retention_first_thirty_seconds" label='Retention at 30 sec' />

      </Datagrid>
  </List>
);
}

const Thumbnail = () =>{
  const record = useRecordContext()
  if(!record) return null;
  let thumbnailHighRes = record['thumbnail_url'].replace('default','maxresdefault')
  return (
    <a href={thumbnailHighRes} target='_blank' rel='noreferrer'>
      <img src={record['thumbnail_url']} alt ={record['title']} style = {{height:'6em'}}/>
    </a>
)
}
const Link = ({source}) =>{
  const record = useRecordContext()
  return record ? (
      <a href={'https://www.youtube.com/watch?v='+record['video_id']} target='_blank' rel='noreferrer'>
      {record['title']}
    </a>
) : null;
}