import * as React from 'react';
import { useDataProvider,} from 'react-admin';
import PropTypes from 'prop-types';
import MaterialButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/system/Unstable_Grid';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function VideoPerformanceModal({channelId}) {
    const [open, setOpen] = React.useState(false);
    const [images, setImages] = React.useState([])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <MaterialButton  onClick={handleClickOpen}>
            <LatestVideoPerformance channelId = {channelId} setImages ={setImages}/>
            </MaterialButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Latest Video Performances Screenshots
                </BootstrapDialogTitle>
                <DialogContent dividers>
                {images &&
                    <Grid container spacing={0} >  
                        {images.map((item,key)=>
                        <Grid key={key} xs={12}>
                            <div style={{textAlign:"center", fontWeight:"bold"}}>
                            <h4>{item.date}</h4>
                            <img key={key}  src={item.imgUrl}   />
                            <hr/>
                            </div>
                        </Grid>)} 
                    </Grid>
                }
                 </DialogContent>
                 
            </BootstrapDialog>
        </div>
  );
}

const LatestVideoPerformance = ({channelId, setImages}) => {
  
    const [latestVideoPerformances, setLatestVideoPerformances] = React.useState([])
    const dataProvider = useDataProvider()
    React.useEffect(() => {
       if(channelId){
        dataProvider.getList('latestVideoPerformances', {ownedChannelsId:channelId}).then(({data})=>{
            setImages(data)
            data = data.slice(0,6)
          let mappedData = data.map((item,key)=><div style={{textAlign:"center"}}>{item.date}<img key={key} className='carousalImageMask' src={item.imgUrl} height="320px" /></div>)
          setLatestVideoPerformances(mappedData)
        })
      }
    },[channelId])
  
    return (
      <Grid container spacing={0} >
        {latestVideoPerformances.map((item,key)=><Grid key={key} xs={2}>{item}</Grid>)}
      </Grid>
    )
  }