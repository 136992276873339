import axios from 'axios'
import { HttpError } from 'react-admin';
import endpoints from './endpoints.json'

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getTrendingTags = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order,
        project: params.filter.project
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.trendsEndpoint+'tags', {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
    console.log(rawData)
    const mappedData = rawData.data.map(entry =>
        ({
            id: entry.id,
            tagName: entry.tagName,
            timestamp: entry.timestamp,
            hasTrends: entry.hasTrends,
            sampleUrls: entry.sampleUrls,
            project:entry.project
        }))
    return {data:mappedData,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: mappedData.length < perPage ? false : true}
    }
}

export const getTrendingTag = async(params)=>{

    const rawData = await axios.get(endpoints.backendUrl+endpoints.trendsEndpoint+`tags/${params.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    console.log(rawData)
    const mappedData = rawData.data.map(entry =>
        ({
            id: entry.id,
            tagName: entry.tagName,
            timestamp: entry.timestamp,
            hasTrends: entry.hasTrends,
            sampleUrls: entry.sampleUrls,
            project:entry.project
        }))
    return {data:mappedData[0]}    
}

export const createTrendingTag = async(params)=>{
    
    if(params.data.project === '')
        return Promise.reject(new HttpError(
            "No Project selected",
            404,
            {}
        ))
    const tags = params.data.tags.split(/\r?\n/)
    const sampleUrls = params.data.sampleUrls.split(/\r?\n/)
    
    const mappedParams = {
        project: params.data.project,
        tags: tags,
        sampleUrls: sampleUrls

    }
    const rawData = await axios.post(endpoints.backendUrl+endpoints.trendsEndpoint+'tags' ,mappedParams, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const mappedData = rawData.data.map(entry =>
        ({
            id: entry.id,
            tagName: entry.tagName,
            timestamp: entry.timestamp,
            sampleUrls: entry.sampleUrls,
            hasTrends: entry.hasTrends,
            project:entry.project
        }))
    return {data:mappedData[0]}
}
export const deleteTrendingTag = async(params)=>{
    const data = params.previousData
    await axios.delete(endpoints.backendUrl+endpoints.trendsEndpoint+'tags', {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: {ids:params.id, project:data.project}})
    return {data:data}
}
export const deleteTrendingTags = async(project, ids)=>{
    await axios.delete(endpoints.backendUrl+endpoints.trendsEndpoint+'tags', {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: {ids: ids.join(), project:project}})
    return {data:ids}
}

export const getRelatedTrends = async(params)=>{
    //console.log(params)
        const rawData = await axios.get(endpoints.backendUrl+endpoints.trendsEndpoint+`related?tag=${params.filter.tag}&duration=${params.filter.duration}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
        console.log(rawData) 
        if(rawData.data.error){
            return Promise.reject(new HttpError(
                "Google Trends Server Busy, try again later",
                429,
                {}
            ))  
        } 
        const mappedData = rawData.data.map((entry, index )=>
            ({
                id: index,
                query: entry.query,
                value: entry.value,
                formattedValue: entry.formattedValue,
                link:entry.link
            }))  
        return {data:mappedData,pageInfo: {hasPreviousPage: false, hasNextPage:false}
        }
}