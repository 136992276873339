import {SimpleShowLayout, Show,useShowContext, useRecordContext, useDataProvider, TopToolbar, ListButton, usePermissions, useNotify, useRefresh } from 'react-admin';
import Grid from '@mui/system/Unstable_Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ToggleButton from '@mui/material/ToggleButton';
import React, {useEffect, useRef, useState} from "react";
import {Chart, LineElement,LineController, LinearScale, CategoryScale, PointElement, Tooltip, Filler} from 'chart.js'
Chart.register(LineElement,LineController, LinearScale, CategoryScale, PointElement, Tooltip, Filler)
let  myChart = null

 const OwnChannelShow = () => {
    const refresh = useRefresh()
    const {permissions} = usePermissions()
    useEffect(()=>{
        if (typeof window.plausible === 'function') {
            const userEmail = localStorage.getItem('email')
            window.plausible('Show Own Channel', {props: {user: userEmail}})
        }
      },[])

        return (
            <Show title= {<Title/>}>
                <TopToolbar>
                    <ListButton />
                </TopToolbar>
                <SimpleShowLayout>
                    <Content/>
                    <RelatedVideos/>
                    {(permissions === 'superuser' || permissions === 'manager') && <Settings refresh = {refresh} permissions={permissions}/>}
                </SimpleShowLayout>
            </Show>
        );
    }

const Title = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>{record.name}</span>;
}

const Content = () =>{
    const {record} = useShowContext()
    return <>
    <h2>Channel Details</h2>
    <Paper elevation={3} style={{padding: '10px'}}>
        <Grid container spacing={2} >
            <Grid xs={4}>
                <Grid container spacing={2}>
                    <Grid xs={12}><b>Average Views in the last 2 Hours: </b> {record.averageViewsLast2h}</Grid>
                    <Grid xs={12}><b>Average views in the last 24 hours: </b> {record.averageViewsLast24h}</Grid>
                    <Grid xs={12}><b>Average Click Through Rate: </b> {record.averageCtr}</Grid>
                </Grid>
            </Grid>
            <Grid xs={4}>
                <Grid container spacing={2}>
                    <Grid xs={12}><b>Average Retention Percentage: </b> {record.averageRetentionPercentage}</Grid>
                    <Grid xs={12}><b>Average Retention Duration: </b> {record.averageRetentionDuration}</Grid>
                    <Grid xs={12}><b>Average Retention First 3 Minutes: </b> {record.averageRetentionPercentageFirst3Minutes}</Grid>
                </Grid>
            </Grid>
            <Grid xs={4}>
                <Grid container spacing={2}>
                    <Grid xs={12}><b>Average Retention 30 Seconds: </b> {record.averageRetentionFirstThirtySeconds}</Grid>
                    <Grid xs={12}><b>Average Retention Score (1 min): </b> {record.averageWeightedRetentionFirstMinute}</Grid>
                </Grid>
            </Grid>
        </Grid>             
    </Paper>
    
    <Paper elevation={3} style={{padding: '10px'}}>
        <Grid container spacing={2} >
            <Grid xs={4}>
                <Grid container spacing={2}>
                    <Grid xs={12}><b>Average Retention Percentage at {record.retentionSnapshotThreshold} Views: </b> {record.averageRetentionPercentageSnapshot}</Grid>
                    <Grid xs={12}><b>Average Retention Duration at {record.retentionSnapshotThreshold} Views: </b> {record.averageRetentionDurationSnapshot}</Grid>
                    <Grid xs={12}><b>Average Retention First 3 Minutes at {record.retentionSnapshotThreshold} Views: </b> {record.averageRetentionPercentageFirst3Min_snapshot}</Grid>
                </Grid>
            </Grid>
            <Grid xs={4}>
                <Grid container spacing={2}>
                    <Grid xs={12}><b>Average Retention 30 Seconds at {record.retentionSnapshotThreshold} Views: </b> {record.averageRetentionFirstThirtySecondsSnapshot}</Grid>
                    <Grid xs={12}><b>Average Retention Score (1 min) at {record.retentionSnapshotThreshold} Views: </b> {record.averageWeightedRetentionFirstMinuteSnapshot}</Grid>
                </Grid>
            </Grid>
        </Grid>             
    </Paper>
    </>
}

const RelatedVideos = () =>{
    const {record} = useShowContext()
    const dataProvider = useDataProvider()
    const [relatedVideos, setRelatedVideos] = useState([])
    const [showAll, setShowAll] = useState(true)
    useEffect(() => {
        if(record){
            const data = dataProvider.getList('relatedVideos', {ownedChannelsId: record.id})
            .then(({ data }) => {
                setRelatedVideos(data)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [record])

    if (!record) return null;
    return <Paper elevation={3} style={{padding: '10px'}}>
        <h2>Top Related Videos</h2>
        <Grid container spacing={2} style={{fontWeight: "bold"}} >   
            <Grid xs={2}>Number of Videos</Grid>
            <Grid xs={1} sx={{textAlign:"right"}}>Total Views</Grid>
            <Grid xs={4}>Youtube Link</Grid>
            <Grid xs={3}><ToggleButton size='small' value="check" selected={showAll} onChange={() => {setShowAll(!showAll)}} >
                {showAll ? <>Exclude Same Channel</> : <>Include Same Channel</>}
                </ToggleButton></Grid>
        </Grid>
        {relatedVideos.map((row,index) => {
            if((!showAll && !row.isExternal) || index >50) return null
        return(
            <Grid key={row.id} container spacing={2} >   
                <Grid xs={2}>{row.count}</Grid>
                <Grid xs={1} sx={{textAlign:"right"}}>{row.views}</Grid>
                <Grid xs={4}><a href={row.videoUrl} target='_blank'>{row.videoUrl}</a></Grid>
                <Grid xs={3}>{row.isExternal ? <>Other Channel</> : <>Same Channel</>}</Grid>
            </Grid>    
        )})}  
        
    </Paper>
}

const Settings = ({refresh, permissions}) =>{
    const {record} = useShowContext()
    const [threshold, setThreshold] = useState(record.retentionSnapshotThreshold)
    const [thumbnailUpdateThreshold, setThumbnailUpdateThreshold] = useState(record.thumbnailUpdateThreshold)
    const[trendsetterThreshold, setTrendsetterThreshold] = useState(record.trendsetterThreshold)
    const dataProvider = useDataProvider()
    const notify = useNotify()
    console.log(record)
    if (!record) return null;
    const updateThreshold =() =>{
        if(threshold && threshold > 0){
            const data = dataProvider.updateRetentionThreshold({ownedChannelId: record.id, retentionSnapshotThreshold: threshold}).then(({ data }) => {
                notify("Threshold Updated. This may take some minutes.")
            }).catch((error) => {
                console.log(error)
                notify('Error Updating Threshold')
            }
            )
        }
        else{
            notify('Invalid Threshold')
            console.log(threshold)
        }
    }

    const updateThumbnailThreshold =() =>{
        if(thumbnailUpdateThreshold ){
            const data = dataProvider.updateThumbnailThreshold({id: record.id, thumbnailUpdateThreshold: thumbnailUpdateThreshold}).then(({ data }) => {
                notify("Threshold Updated.")
                refresh()
            }).catch((error) => {
                console.log(error)
                notify('Error Updating Threshold')
            }
            )
        }
        else{
            notify('Invalid Threshold')
            console.log(threshold)
        }
    }
    const setVideoIdeasUpdateFrequency = (frequency) =>{       
            const data = dataProvider.updateVideoIdeasUpdateFrequency({ownedChannelId: record.id, videoIdeasUpdateFrequency: frequency}).then(({ data }) => {
                refresh()
            }).catch((error) => {
                console.log(error)
                notify('Error Updating Video Idea Frequency')
            }
            )
    }

    const updateTrendsetterThreshold = () =>{       
        const data = dataProvider.updateTrendsetterThreshold({id: record.id, trendsetterThreshold: trendsetterThreshold}).then(({ data }) => {
            refresh()
        }).catch((error) => {
            console.log(error)
            notify('Error Updating Video Idea Frequency')
        }
        )
    }

    return <Paper elevation={3} style={{padding: '10px'}}>
        <h2>Settings</h2>
        <Grid container spacing={1} alignItems="center" >
            <Grid xs={4}>
                <h3>Channel Snapshots (at {record.retentionSnapshotThreshold} Views)</h3>
            </Grid>
            {(permissions === 'superuser' ?
            <>
                <Grid xs={1}>
                    <TextField id="outlined-basic" label="Threshold" defaultValue={record.retentionSnapshotThreshold} variant="outlined" onChange={(e)=>setThreshold(e.target.value)}  />
                </Grid>
                <Grid xs={7}>
                    <Button variant="contained" onClick={updateThreshold}>Update Threshold</Button>
                </Grid>
            </>
            : <Grid xs={8}/>)}

            <Grid xs={4}>
                <h3>Thumbnails Update Threshold (at {record.thumbnailUpdateThreshold} Views)</h3>
            </Grid>
            {(permissions === 'superuser' || permissions === 'manager' ?
            <>
            <Grid xs={1}>
                <TextField id="outlined-basic" label="Threshold" defaultValue={record.thumbnailUpdateThreshold} variant="outlined" onChange={(e)=>setThumbnailUpdateThreshold(e.target.value)}  />
            </Grid>
            <Grid xs={7}>
                <Button variant="contained" onClick={updateThumbnailThreshold}>Update Threshold</Button>
            </Grid>
            </>
            :<Grid xs={8}/>)} 
            <Grid xs={4}>
                <h3>Video Trendsetters Threshold (at {record.trendsetterThreshold} Views)</h3>
            </Grid>
            {(permissions === 'superuser' ?
            <>
                <Grid xs={1}>
                    <TextField id="outlined-basic" label="Threshold" defaultValue={record.trendsetterThreshold} variant="outlined" onChange={(e)=>setTrendsetterThreshold(e.target.value)}  />
                </Grid>
                <Grid xs={7}>
                    <Button variant="contained" onClick={updateTrendsetterThreshold}>Update Threshold</Button>
                </Grid>
            </>
            : <Grid xs={8}/>)} 
            {(permissions === 'superuser' ?
            <>
            <Grid xs={5}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Video Idea Generation Frequency</InputLabel>
                    <Select labelId="demo-simple-select-label" value={record.videoIdeasUpdateFrequency} label="Video Idea Frequency" onChange={(e)=>setVideoIdeasUpdateFrequency(e.target.value)}>
                        <MenuItem value={1}>Daily</MenuItem>
                        <MenuItem value={7}>Weekly</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid sx={7}/>
            </>
            : null)}
        </Grid>
    </Paper>
}

const ViewChart =()=>{
    const {record} = useShowContext()
    const ctx = useRef()
    useEffect(() => {
        Tooltip.positioners.bottom = function(items, eventPosition) {      
            const chart = this.chart;
          
            return {
              x: chart.chartArea.left,
              y: chart.chartArea.bottom,
              xAlign: 'center',
              yAlign: 'bottom',
            };
          };
        let labels = []
        for (let inc=0; inc<record.averageRetention.length*5;inc+=5){
            const paramName = secondsToHms(inc)
            labels.push(paramName)
        }
         
        const viewData = record.averageRetention
        
        if (myChart != null) {
            myChart.destroy();
        }
        myChart = new Chart(ctx.current, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: "Retention Percentage",
                    data: viewData,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                    tension: 0
                }
            ]
            },
            options: {
                interaction: {
                    intersect: false,
                    mode: 'index',
                }
            }
        })
        return ()=>{
            if(myChart instanceof Chart)
            {
                myChart.destroy();
            }
        }
    }, [])

    if (!record || ctx.current === null) return <></>;

 

    return <div><canvas ref={ctx} width="500" height="100" ></canvas></div>
}
// change seconds to hh:mm:ss
const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);
}

export default OwnChannelShow

