import {useEffect, useState} from 'react'
import { Card } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom"
import { 
  Datagrid,  
  List, 
  TextField,
  NumberInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DeleteButton,
  ShowButton,
  EditButton
   } from 'react-admin';
import Grid from '@mui/system/Unstable_Grid'


   export const RolesList = () => {
    useEffect(()=>{
      if (typeof window.plausible === 'function') {
        const userEmail = localStorage.getItem('email')
        window.plausible('List Roles', {props: {user: userEmail}})
      }
    },[])
    return(
    <List title="Roles" >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="default_percentage" />
          <EditButton/>
          <DeleteButton/>
        </Datagrid>
    </List>
  )
}

  export const RolesCreate = () => (
    <Create>
      <SimpleForm>
        <TextInput source='name' label='Name' resettable/>
        <NumberInput source="default_percentage" label="Default Percentage" min={0} max={100} step={5} sx={{minWidth:"200px"}}/>
      </SimpleForm>
    </Create>
  )
 
  export const RolesEdit = () => (
    <Edit>
    <SimpleForm>
      <TextInput source="name" label="Name" />
      <NumberInput source="default_percentage" label="Default Percentage" min={0} max={100} step={5}/>
    </SimpleForm>
  </Edit>
  )