import axios from 'axios'
import endpoints from './endpoints.json'
import { HttpError } from 'react-admin';
import { getS3Bucket } from './awsAccess';

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

window.Buffer = window.Buffer || require("buffer").Buffer;

export const getProposals = async(type, params)=>{
    const { page, perPage } = params.pagination;
    const project = params.filter.project === undefined ? '_kids' : params.filter.project
    const queryParams ={
        type: type,
        project:project,
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order
    }
    //console.log(queryParams)
        const rawData = await axios.get(endpoints.backendUrl+endpoints.proposals, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
       // console.log(rawData)
        const mappedData = rawData.data.map(entry=>({
            id:entry.id,
            workingTitle:entry.workingTitle, 
            sampleUrls: entry.sampleUrls,
            images: entry.image,
            timestamp:entry.timestamp, 
            createDate: new Date(entry.timestamp),
            tags:entry.tags,
            name:entry.name,
            description:entry.description,
            hasComments: entry.hasComments,
            status: entry.status,
            project: entry.project}))
        return {data:mappedData,
            pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: mappedData.length < perPage ? false : true}
        }
}

export const getProposal = async(params)=>{
   // console.log(params.id)
    const rawData = await axios.get(endpoints.backendUrl+endpoints.proposals +'/'+ params.id, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const images = await getImages(params.id)

    const mappedData = await rawData.data.map(entry=>({
        id:entry.id,
        workingTitle:entry.workingTitle, 
        sampleUrls: entry.sampleUrls.replaceAll(',', "\n"),
        images:images,
        timestamp:entry.timestamp, 
        createDate: new Date(entry.timestamp),
        tags:entry.tags,
        name:entry.name,
        description:entry.description.replaceAll("'",""),
        status: entry.status,
        project:entry.project
    }))
    console.log(mappedData)
    return {data:mappedData[0]}
}
export const createProposal = async(type,params)=>{
    if(params.data.project === '')
        return Promise.reject(new HttpError(
            "No Project selected",
            404,
            {}
        ))
    let sampleUrls = params.data.sampleUrls.split(/\r?\n/).join()
    const mappedParams = {
        type:type,
        project: params.data.project,
        workingTitle: params.data.workingTitle,
        sampleUrls: sampleUrls,
        name: params.data.name,
        description: params.data.description,
        tags: params.data.tags
    }
    const rawData = await axios.post(endpoints.backendUrl+endpoints.proposals ,mappedParams, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    await uploadImages(rawData.data.insertId, 'proposalImages', params.data.images)
    //console.log(rawData)
    const mappedData = {
        id:rawData.data.insertId,
        timestamp:rawData.data.timestamp, 
        createDate: new Date(rawData.data.timestamp),
        project: params.data.project,
        workingTitle: params.data.workingTitle,
        sampleUrls: sampleUrls,
        videoUrls:[],
        name: params.data.name,
        description: params.data.description,
        tags: params.data.tags
    }
    return {data:mappedData}
}

export const updateProposal = async(params) => {
    const rawData = await axios.put(endpoints.backendUrl+endpoints.proposals ,params.data, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    await uploadImages(params.data.id, 'proposalImages', params.data.images)
    return {data:params.data}
}

export const deleteProposal = async(params) => {
    const data = params.previousData
    await axios.delete(endpoints.backendUrl+endpoints.proposals+'/'+ params.id, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:data}
}

export const getProposalComments = async(params)=>{
    //console.log(params)
        const rawData = await axios.get(endpoints.backendUrl+endpoints.proposalsComments+params.filter.proposalId, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
        //console.log(rawData)
        
        return {data:rawData.data,pageInfo: {hasPreviousPage: false, hasNextPage:false}
        }
}

export const createProposalComment = async(params)=>{
    
    if(params.data.project === '')
        return Promise.reject(new HttpError(
            "No Project selected",
            404,
            {}
        ))
    const mappedParams = {
        proposalId: params.data.proposalId,
        commenter: params.data.commenter,
        comment: params.data.comment
    }
    const rawData = await axios.post(endpoints.backendUrl+endpoints.proposalsComments ,mappedParams, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    //console.log(rawData)
    const mappedData = {
        id:rawData.data.insertId,
        proposalId: params.data.proposalId,
        commenter: params.data.commenter,
        comment: params.data.comment
    }
    return {data:mappedData}
}

export const deleteProposalImage = async(params) => {
    
    const data = await axios.delete(endpoints.backendUrl+endpoints.files + `?type=proposal&foreignKey=${params.foreignKey}&fileName=${params.fileName}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:data}
}
//upload image to s3 bucket
export const uploadImages = async(proposalId, folder, images)=>{    

    const myBucket = await getS3Bucket()
    const urls =[]
    images = images.filter(image=>image.rawFile instanceof File)
    for(const image of images){
       const imageTitle = `${proposalId}_${image.title}`
        const fileBuffer = await readFileAsync(image.rawFile)
        const params = {
            Body: fileBuffer,
            Bucket: 'flocal',
            Key: `${folder}/${imageTitle}`,
            ContentType: image.rawFile.type
        };
        try{
        await myBucket.upload(params).promise() 
        }catch(err){
            console.log(err)
        }   
        urls.push(`https://flocal.s3-us-west-1.amazonaws.com/${folder}/${imageTitle}`)
    }
    await axios.post(endpoints.backendUrl+endpoints.files ,{type:"proposal",foreignKey:proposalId, urls: urls}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
}

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onabort = () => reject('file reading was aborted')
        reader.onerror = () => reject('file reading has failed')
      reader.onload = () => {
        resolve(reader.result);
      };   
      reader.readAsArrayBuffer(file);
    })
  }
const getImages = async(proposalId)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.files+`?type=proposal&foreignKey=${proposalId}`,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const images = rawData.data.map(image=>({url:image.url, fileName:image.fileName}))
    return images
}
