import React,{useState, useEffect} from "react";
import { 
    Card, 
    CardContent,
 } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { SelectChannel } from "./miscellanious/utils";
 import 
 {  
    Link, 
    useDataProvider, 
    usePermissions,
    Loading
    } 
    from 'react-admin';


const Dashboard = () => {
    const dataProvider = useDataProvider()
    const [channel, setChannel] = useState(null)
    const {permissions} = usePermissions()

    useEffect(() => {
        dataProvider.getDefaultChannel().then((result) => setChannel(result || {id: 1, name: 'Toys and Colors', project: '_kids'})
        ).catch((error) => {
            console.log(error)
            setChannel({id: 1, name: 'Toys and Colors', project: '_kids'})
        })

    }
    , [])

    if(!channel) return <Loading/>
    return(
    <>
    { (permissions !== 'external')  &&
    <Grid container>
        <Grid xs={12}>
        <SelectChannel channel ={channel} setChannel={setChannel}/>
        </Grid>
        <Grid xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2} >
                        <BestExternalPerformers channel={channel} sort='viewsLast2h' title='Most Views in the Last 2 Hours'/>
                        <BestExternalPerformers channel={channel} sort='videoPerformance' title='Most Views in the Last 2 Hours compared to Channel Average Views (CAV)'/>
                        <BestExternalPerformers channel={channel} sort='videoPerformance' showTrendsetters={true} title='Trend Setters last 2 Hours'/>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    }
    </>
);
}
export default Dashboard;

const BestExternalPerformers =({channel, sort, showTrendsetters, title})=>{
    const dataProvider = useDataProvider()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {       
        if(channel.id){
            const project = channel.project?.length > 0 ? channel.project:'_kids'
            setLoading(true)           
            const optionalFilter = showTrendsetters ? `viewsLast2h > ${channel.trendsetterThreshold || 0}` : null
            dataProvider.getList('externalVideos', {pagination: {page: 1, perPage: 12}, filter:{project: project}, sort: {field: sort, order: 'DESC'}, meta: {optionalFilter: optionalFilter}})
            .then(({ data }) => {
                //console.log("Videos: ",data)
                return dataProvider.translate(data)
            }).then((data) => {
                setData(data)
                //console.log("Translated:", data)
            }).catch((error) => {
                console.log(error)
            }).finally(()=>{
                setLoading(false)
            })
        }
    }, [channel])
    if (!data) return <Loading/>

    return (        
        <Grid xs={12}>
             <Grid container spacing={2} >
                <Grid xs={12}><h2>{title}</h2></Grid>
                {data.map((row, index) => (       
                <Grid xs={2} key={index}>
                    <Card elevation={5}  sx={{padding:"2px", backgroundColor:"#FAF9F6", '&:hover':{backgroundColor: '#63B4B8'}, textAlign:"center",borderRadius: '5px'}}>
                    <Link to={`/externalVideos/${row.id}/show`} sx={{color:"#383838",'&:hover':{color:"#FAF9F6"}}}>
                            <Grid container spacing={2} key={index} >
                                <Grid xs={12} style={{maxHeight:"50px", minHeight:"50px", overflow:"hidden"}}><b title={row.title} dangerouslySetInnerHTML={{__html: row.title}}></b></Grid> 
                                <Grid xs={12} sx={{paddingBottom:0}}><img src={row.thumbnail.replace('default','maxresdefault')} alt={row.title} style={{width:"95%"}} /></Grid>
                                <Grid xs={12} sx={{textAlign:"center", marginTop:"-10px"}}><b title={`${Math.round(row.videoPerformance)}x Channel Average Views`}>{Math.round(row.videoPerformance)}x CAV</b><br/><b>{row.channelTitle}</b></Grid>
                            </Grid>
                            </Link>
                    </Card>
                </Grid>                  
            ))}  
            </Grid>       
        </Grid>      
    );
}
const BestRelatedVideos =({channel, title})=>{
    const [relatedVideos, setRelatedVideos] = useState([])
    const dataProvider = useDataProvider()
    useEffect(() => {
       // console.log(channel)
        if(channel.id){
            const data = dataProvider.getList('relatedVideos', {ownedChannelsId: channel.id})
            .then(({ data }) => {
                const filteredData = filterRelatedVideos(data)
                setRelatedVideos(filteredData)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [channel])
    return (        
        <Grid xs={12}>
             <Grid container spacing={2} >
             <Grid xs={12}><b >{title}</b></Grid>
                {relatedVideos.map((row, index) => (       
                <Grid xs={3}>
                    <Grid container spacing={2} key={index} >
                        <Grid xs={12}><YoutubeEmbed videoId={row.videoId} width={320} height={240}/></Grid> 
                        <Grid xs={12} style={{textAlign:"center"}}><b>Generated Views: {row.views}</b></Grid>
                    </Grid>
                </Grid>                  
            ))}  
            </Grid>       
        </Grid>      
    );
}

const filterRelatedVideos = (data) => {
    const filteredData = data.filter((record) => record.isExternal)
    return filteredData.splice(0, 4)
}
const YoutubeEmbed = ({ videoId, width, height }) => (
    <div className="video-responsive">
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
