import axios from 'axios'
import endpoints from './endpoints.json'
import { HttpError } from 'react-admin';

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getProjects = async()=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.projectsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    const data = rawData.data.map(project=>({id:project.id,name:project.name.replaceAll('_',' '), dbName:project.name, channels:project.channels}))  
    return {data:data,total:data.length}
        
}

export const getProject = async(params)=>{
    const id = parseInt(params.id)
    const rawData = await axios.get(endpoints.backendUrl+endpoints.projectsEndpoint,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    const data = rawData.data.map(project=>({id:parseInt(project.id),name:project.name.replaceAll('_',' '), dbName:project.name, channels:project.channels})).find(project=>project.id===id)  
    return {data:data}        
}
export const createProject = async(params)=>{   
    const projectName = '_' + params.data.projectName.replaceAll(' ','_')
    const rawData = await axios.post(endpoints.backendUrl+endpoints.projectsEndpoint,{projectName:projectName},{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    const data ={id:rawData.data.insertId,projectName:projectName}
    return {data}       
}
export const updateProject = async(params)=>{ 
    const projectName = '_' + params.data.name.replaceAll(' ','_')
    const rawData = await axios.put(endpoints.backendUrl+endpoints.projectsEndpoint,{id:params.data.id,projectName:projectName}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    return {data:params.data}       
}
export const deleteProject = async(params)=>{
    const rawData = await axios.delete(endpoints.backendUrl+endpoints.projectsEndpoint+`?projectsId=${params.projectsId}&projectName=${params.projectName}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    return {}       
}
export const deleteProjectChannels = async(params)=>{
    const rawData = await axios.delete(endpoints.backendUrl+endpoints.projectsEndpoint+`/channels?projectsId=${params.projectsId}&projectName=${params.projectName}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    return {}       
}
export const getApiKeys = async(params)=>{
    const projectsId = params.meta.projectsId
    const rawData = await axios.get(endpoints.backendUrl+endpoints.apiKeysEndpoint+`?projectsId=${projectsId}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    return {data:rawData.data,total:rawData.data.length}
        
}
export const createApiKey = async(params)=>{
    const rawData = await axios.post(endpoints.backendUrl+endpoints.apiKeysEndpoint,params.data,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    const data ={id:rawData.data.insertId,...params.data.apiKeys[0]}
    return {data}       
}
export const deleteApiKey = async(params)=>{
    const id = params.id
    const rawData = await axios.delete(endpoints.backendUrl+endpoints.apiKeysEndpoint+`?apiKeyId=${id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    return {}       
}