import axios from 'axios'
import { HttpError } from 'react-admin';
import endpoints from './endpoints.json'

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getUsers = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order,
        searchPhrase: params.filter?.searchPhrase,
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.usersEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})  
    let data = rawData.data
    data.roles=[] 
    return {data:data,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: rawData.data.length < perPage ? false : true}
    }
}

export const getUser = async(params)=>{

    const rawData = await axios.get(endpoints.backendUrl+endpoints.usersEndpoint + params.id, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    //console.log(rawData)
    const data ={
        id: rawData.data.id,
        createDate: rawData.data.createDate,
        name: rawData.data.name,
        team: rawData.data.team,
        email: rawData.data.email,
        chatId: rawData.data.chatId,
        roles: Object.keys(rawData.data.roles).length === 0 ? [] : rawData.data.roles,
        channels: Object.keys(rawData.data.channels).length === 0 ? [] : rawData.data.channels
    }
    return {data:data}    
}

export const getUserRoles = async(params)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.userRolesEndPoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params})
    if (!Array.isArray(rawData?.data)) return {data:[]}
    const mappedData = rawData.data.map(entry =>
        ({
            id: entry.id,
            name: entry.name
        }))
        //console.log(mappedData)
    return {data:mappedData}    
}

export const getUserChannels = async(params)=>{

    const rawData = await axios.get(endpoints.backendUrl+endpoints.userChannelsEndPoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params})
    if (!Array.isArray(rawData?.data)) return {data:[]}
    const mappedData = rawData.data.map(entry =>
        ({
            id: entry.id,
            name: entry.name
        }))
    return {data:mappedData}    
}

export const createUser = async(params)=>{
    const rawData = await axios.post(endpoints.backendUrl+endpoints.usersEndpoint ,params.data, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    //console.log(rawData)
    const mappedData = {
            id: rawData.data.id,
            name: params.data.name,
            team: params.data.team,
            roles: params.data.roles,
            channels: params.data.channels,
            email: params.data.email,
            chatId: params.data.chatId
        }
    return {data:mappedData}
}

export const updateUser = async(params) => {
    const rawData = await axios.put(endpoints.backendUrl+endpoints.usersEndpoint+params.data.id ,params.data, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:params.data}
}

export const deleteUser = async(params)=>{
    const data = params.previousData
    await axios.delete(endpoints.backendUrl+endpoints.usersEndpoint+`${params.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:data}
}

export const createRole = async(params)=>{
    const rawData = await axios.post(endpoints.backendUrl+endpoints.rolesEndPoint ,params.data, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    //console.log(rawData)
    const mappedData = {
            id: rawData.data.id,
            name: params.data.name,
            default_percentage: params.data.default_percentage,
        }
    return {data:mappedData}
}

export const getRoles = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.rolesEndPoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})   
    return {data:rawData.data,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: rawData.data.length < perPage ? false : true}
    }
}

export const getRole = async(params)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.rolesEndPoint + params.id, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
   // console.log(rawData)
    const data ={
        id: rawData.data.id,
        name: rawData.data.name,
        channels: rawData.data.default_percentage
    }
    //console.log(data)
    return {data:data}    
}

export const updateRole = async(params) => {
    const rawData = await axios.put(endpoints.backendUrl+endpoints.rolesEndPoint+params.id ,params.data, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:params.data}
}

export const deleteRole = async(params)=>{
    const data = params.previousData
    await axios.delete(endpoints.backendUrl+endpoints.rolesEndPoint+`${params.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:data}
}

//TEMPORARY
export const getOwnedChannels = async(params)=>{

    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.userOwnedChannelsEndPoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})   
    return {data:rawData.data,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: rawData.data.length < perPage ? false : true}
    }
}